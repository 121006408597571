import React, {useEffect, useRef, useState} from "react";
import NavBar from "./NavBar";
import { getNextVideo, capitalizeFirstLetter, getFirstCat, convertTimeStringToInt, saveVideo, delayVideo, retranscodeVideo, getCookie } from "../scripts/xhrf";
import Highlighter from "react-highlight-words";
import { Tag, RejectOverlay, NewUserInfo } from "./PageSnippets";

export default function VideoRateApp(){

  // Aushilfen sollen kein Video raten
  if(!(getCookie("internal") === "X")){
    window.location.href = "/home"
  }

  // RatingCat ist für die Auswahl der Unterkategorien wichtig. Rating und Rerating haben jeweils eine eigene Nummer
  const ratingCat = (sessionStorage.getItem('mode')===null ? 3 : (sessionStorage.getItem('mode')==="check"? 3 : 4));

  // In der secondaryCategory wird die Unterkategorie gepseichert, die beim Wechsel dieser einen Reload triggert
  const [secondaryCategory,setSecondaryCategory] = useState(getFirstCat(ratingCat));

  // In vid werden alle informationen über das Video gespeichert
  const [vid,setVid] = useState({});

  // Referenz auf das Video
  const vidRef = useRef(null);

  // Status zum manuellen refresh
  const [doRefresh,setDoRefresh] = useState(false);

  // Informationen über die Webapplikation werden hier gespeichert
  const [appStatus,setAppStatus] = useState({
    sort : false,
    slot : -1,
    premiumOnly : false,
    albumId : "",
    userId : ""
  });

  const [loading,setLoading] = useState(true);  // state to show loading circle

  // Fetches Data on load
  useEffect(()=>{
    // Fetching Data and initializing appStatus
    async function getData(){
      let searchAlbum = 0;
      let searchUser = 0;

      if(window.location.pathname !=="/videoRating"){
        searchAlbum = window.location.pathname.split("/")[2];
      }else if(appStatus.albumId !== undefined && appStatus.albumId !== ""){
        searchAlbum = appStatus.albumId;
      }else if(appStatus.userId !== undefined && appStatus.userId !== ""){
        searchUser = appStatus.userId;
      }
      let v = await getNextVideo(searchAlbum,searchUser,(sessionStorage.getItem('sc') === "-1" ? 'SHOP' : sessionStorage.getItem('sc') ?? 'SHOP'),appStatus.sort,appStatus.slot,appStatus.premiumOnly,sessionStorage.getItem('mode') ?? "check");
      if(v.errors !== undefined){
        alert("Fehler: " + v.errors[0].message);
      }

      // If there is nothing to Rate then show the "nothing to rate" page
      if(v.data.videoRating === null){
        setSecondaryCategory(-1);
        return;
      }else if(searchAlbum !== 0 || searchUser !== 0){
        // Falls nach einem Album gesucht wurde, wird hier die entsprechende Unterkategorie zur Anzeige in den sessionstorage geschrieben
        sessionStorage.setItem('sc',v.data.videoRating.video.ratingGroup);
      }else{
        setSecondaryCategory(0);
      }
      console.log(v.data.videoRating);

      // Makes the Badwords first Letter Case insensitive
      let sw = [];
      for (let e in v.data.videoRating.video.suspectedWords){
        sw.push(v.data.videoRating.video.suspectedWords[e]);
        sw.push(capitalizeFirstLetter(v.data.videoRating.video.suspectedWords[e]));
      }
      v.data.videoRating.video.suspectedWords = sw;

      setVid(v.data.videoRating);
      /* Eine Erklärung zu den Werten in AppStatus:
        selectedRating : Gibt an welches Rating für das gesamte Video ausgewählt ist
        selectedFlags : Gibt an welche Flags ausgewählt sind
        audioQuality : Gibt die ausgewählte Audioqualität an
        previewPictureStats : Beinhaltet Informationen über die Vorschaubilder. Rating, PictureID und ob es sich um ein Vorschaubild handelt
        rejectOverlay : Ob das Rejectoverlay angezeigt wird
        rejectReasonDE : Aktuelle Rejectreason auf Deutsch
        rejectReasonEN : Aktuelle Rejectreason auf Englisch
        bigViewMode : Ob das Vorschaubild im großen Anzeigemodus angezeigt wird
        bigViewPic : Index, welches Bild in der großen Anzeige angezeigt wird
        newMaxPrice : Neuer Maximalpreis
        selectedContest : Ausgewählter Videowettbewerb (Nur beim entsprechenden Videotype wichtig)
        descriptionLanguage : Angezeigte Sprache der Beschreibung
        descriptionEditable : Ob die Bescheibung editierbar ist
        trailerFSK16Start : String wann der Fsk16 Trailer beginnt. Format: "00:00:00"
        trailerFSK16End : String wann der Fsk16 Trailer endet
        trailerFSK18Start : String wann der Fsk18 Trailer beginnt
        trailerFSK18End : String wann der Fsk18 Trailer endet
        maxDuration : Zeitlimit, das bestimmt wie lange der Trailer sein darf. Wird beim Laden des Videos aktualisiert.
        trailerFSK16Disabled : Wenn dies wahr ist kann kein Fsk16 Trailer erstellt werden
        videoSource : Quelle für das Video
        sort : Sortierung nach neuen oder alten videos
        slot : Auswahl von Slots falls mehrere Mitarbeiter Videos gleichzeitig raten müssen
        premiumOnly : Ob nur premiumvideos angezeigt werden sollen
       */
      setAppStatus({
        selectedRating : (v.data.videoRating.video.fsk===16?16:18),
        selectedFlags : (v.data.videoRating.account.platforms.length===1 && v.data.videoRating.account.platforms.includes("VXPAGES")?["vxpages_only"]:[])
                        .concat((v.data.videoRating.video.type === "53_2" ? ["welcomeclip_approved"] : [])).concat(v.data.videoRating.video.flags),
        audioQuality : (v.data.videoRating.video.audioQuality === "unknown" ? 0 : (v.data.videoRating.video.audioQuality==="good" && v.data.videoRating.audioType==="music" ? 2 : (v.data.videoRating.video.audioQuality==="none" && v.data.videoRating.audioType==="other" ? 1 : 0))),
        previewPictureStats : v.data.videoRating.video.pictures.map((x) => JSON.parse(`{"rating":${x.fsk},"pid":${x.pictureId},"previewPic":
                               ${(v.data.videoRating.video.previewPictureIdFsk16===x.pictureId ? 16 : (v.data.videoRating.video.previewPictureIdFsk18===x.pictureId? 18 : 0))}}`)),
        rejectOverlay : false,
        rejectReasonDE : "",
        rejectReasonEN : "",
        bigViewMode : false,
        bigViewPic : 0,
        newMaxPrice : 0,
        selectedContest : "",
        descriptionLanguage : "DE",
        descriptionEditable : false,
        trailerFSK16Start : new Date(v.data.videoRating.video.trailer.startFsk16*1000).toLocaleTimeString('de',{ timeZone: 'UTC' }),
        trailerFSK16End : new Date(v.data.videoRating.video.trailer.endFsk16*1000).toLocaleTimeString('de',{ timeZone: 'UTC' }),
        trailerFSK18Start : new Date(v.data.videoRating.video.trailer.startFsk18*1000).toLocaleTimeString('de',{ timeZone: 'UTC' }),
        trailerFSK18End : new Date(v.data.videoRating.video.trailer.endFsk18*1000).toLocaleTimeString('de',{ timeZone: 'UTC' }),
        maxDuration : "10:00:00",     // Big value; will be replaced when video was loaded
        trailerFSK16Disabled : true,
        videoSource : v.data.videoRating.video.urls[0].format,
        sort : appStatus.sort,
        slot : appStatus.slot,
        premiumOnly : appStatus.premiumOnly,
        albumId : appStatus.albumId,
        userId : appStatus.userId,
        currentPlayback : null
      });
      setLoading(false);
      setDoRefresh(false);
    }
    setLoading(true);
    getData();
  },[doRefresh,secondaryCategory,appStatus.slot,appStatus.sort,appStatus.premiumOnly,appStatus.albumId,appStatus.userId]);

  // Rates the preview Pictures. Value for AgeRating and ind for Picture index
  // Only one Picture can have a PreviewImageFlag
  function previewRating(val,ind){
    let p = appStatus.previewPictureStats;
    switch(val){
      case "18Rating":
        p[ind].rating = 18;
        if(p[ind].previewPic === 16){   // Removes Preview 16 if set
          p[ind].previewPic = 0;
        }
      break;
      case "16Rating":
        p[ind].rating = 16;
      break;
      case "18Preview":
        for(let e in p){
          if(p[e].previewPic !== 16){
            p[e].previewPic = 0;
          }
        }
        p[ind].previewPic = 18;
      break;
      case "16Preview":             // Only possible when Pic is Rated 16
        if(p[ind].rating === 16){
          for(let e in p){
            if(p[e].previewPic !== 18){
              p[e].previewPic = 0;
            }
          }
          p[ind].previewPic = 16;
        }
      break;
      default:
    }
    setAppStatus({
      ...appStatus,
      previewPictureStats : p
    });
  }

  // Sendet die Bewertung des Videos ab
  async function rateVideo(){
    if(videoValidator(appStatus,vid,setAppStatus,true)){
      setLoading(true);
      await saveVideo(vid,appStatus,null,ratingCat);
      cleanUp();
      sessionStorage.setItem("previous",vid.video.albumId);
      setDoRefresh(true);
    }
  }

  // Überspringt das aktuelle Video
  function skipAlbum(){
    let ss = sessionStorage.getItem('skipIds')??"[]";
    ss = JSON.parse(ss);
    if(!ss.includes(vid.video.albumId)){
      ss.push(vid.video.albumId);
    }
    sessionStorage.setItem('skipIds',JSON.stringify(ss));
    cleanUp();
    sessionStorage.setItem("previous",vid.video.albumId);
    setDoRefresh(true);
  }

  // Geht in das vorherige Album zurück indem es nach der ID des Videoalbums sucht
  function previousAlbum(){
    if(sessionStorage.getItem("previous") !== null ){
      //window.location.pathname = "/videoRating/" + sessionStorage.getItem("previous");
      cleanUp();
      setAppStatus({
        ...appStatus,
        albumId : sessionStorage.getItem("previous")
      });
      sessionStorage.removeItem("previous");
    }
  }

  // Lehnt ein Video ab
  async function rejectVideo(rDE,rEN){
    let rejRes = `[{language:"DE", text:"${rDE}"},{language:"EN", text:"${rEN}"}]`;
    setLoading(true);
    await saveVideo(vid,appStatus,rejRes,ratingCat);
    setDoRefresh(true);
  }

  async function doDelay(){
    setLoading(true);
    await delayVideo(vid.video.albumId);
    cleanUp();
    setDoRefresh(true);
  }

  async function doRetranscode(){
    setLoading(true);
    await retranscodeVideo(vid.video.albumId);
    cleanUp();
    setDoRefresh(true);
  }

  function cleanUp(){
    if(appStatus.currentPlayback !== null){
      clearTimeout(appStatus.currentPlayback);
    }
    if(window.location.pathname !== "/videoRating"){
      window.location.pathname = "/videoRating";
    }
  }

  let contentF = <ContentField vid={vid} setVid={setVid} appStatus={appStatus} setAppStatus={setAppStatus} vidRef={vidRef} previewRating={previewRating} skipAlbum={skipAlbum} previousAlbum={previousAlbum} rejectVideo={rejectVideo} rateVideo={rateVideo} doDelay={doDelay} doRetranscode={doRetranscode}/>

  if(loading){      // When loading there is only Navbar and Loading Circle
    contentF = <div className="w-full h-[92vh] bg-zanBg dark:bg-gray-700">
              <img className="h-[5vw] w-[5vw] left-[47.5vw] top-[40vh] absolute blur-sm dark:invert" alt="" src="/icons/ajax-loader-big.gif"></img>
             </div>
  }

  return(
    <div className="bg-blue-200 dark:bg-gray-800 dark:text-white transition-all min-w-[1750px]">
      <NavBar ratingCat={ratingCat}
        setSecondaryCategory={setSecondaryCategory}
      />

      {secondaryCategory !== -1 ? contentF : <p className="absolute top-[10vh] left-2">{"No videos to rate"}</p>}
    </div>
  );
}

// Hauptkomponente für das Videorating
function ContentField({vid,setVid,appStatus,setAppStatus,vidRef,previewRating,skipAlbum,previousAlbum,rejectVideo,rateVideo,doDelay,doRetranscode}){
  let pictureOverlay = null;

  // Setzt alle Previewbilder auf Fsk18 oder 16 
  function setAllPreviews(val){
    let p = appStatus.previewPictureStats;
    for(let e in p){
      p[e].rating = val;
      if(val === 18 && p[e].previewPic === 16){
        p[e].previewPic = 0;
      }
    }
    setAppStatus({
      ...appStatus,
      previewPictureStats : p
    });
  }

  // Switches to previous picture in overlay mode if possible
  const prevImage = () => {
    if(appStatus.bigViewPic > 0){
      setAppStatus({
        ...appStatus,
        bigViewPic : appStatus.bigViewPic - 1
      });
    }
  }
  // Switches to next picture in overlay mode if possible
  const nextImage = () => {
    if(appStatus.bigViewPic+1 < vid.video.pictures.length){
      setAppStatus({
        ...appStatus,
        bigViewPic : appStatus.bigViewPic + 1
      });
    }
  }
  // Overlay showing a single Preview Picture in bigger Size
  if(appStatus.bigViewMode){
    pictureOverlay = <div className="overlay absolute left-[2.1vw] -top-[2vh] h-[90vh] w-[96vw] bg-black bg-opacity-90 rounded-3xl">
      <p className="overlay text-white text-5xl absolute left-5 top-5">{(appStatus.bigViewPic+1)+"/"+vid.video.pictures.length}</p>
      <button className="overlay text-gray-400 text-5xl font-bold absolute right-[1vw] top-[2vh] rounded-full h-14 w-14" onClick={closeOverview}>X</button>
      <a className="overlay w-[80vw] h-[75vh] top-[1vh] left-[7.5vw] absolute" href={vid.video.pictures[appStatus.bigViewPic].urlBig} target="_blank" rel="noreferrer">
        <img className="overlay object-contain w-[80vw] h-[75vh]" src={vid.video.pictures[appStatus.bigViewPic].urlBig} alt="Vorschaubild"></img>
      </a>
      <button className="overlay bg-gray-600 rounded-full absolute top-[40vh] left-[0.8vw] h-[70px] w-[70px]" onClick={prevImage}>
        <p className="overlay text-6xl text-white mx-auto pb-1">{"<"}</p>
      </button>
      <button className="overlay bg-gray-600 rounded-full absolute top-[40vh] right-[0.8vw] h-[70px] w-[70px]" onClick={nextImage}>
        <p className="overlay text-6xl text-white mx-auto pb-1">{">"}</p>
      </button>
      <button className={`overlay ${(appStatus.previewPictureStats[appStatus.bigViewPic].rating === 18 ? "bg-red-600 text-white" : "bg-gray-200 text-black")} rounded-b-3xl absolute bottom-[4vh] left-[15vw] h-[10vh] w-[10vw] text-7xl`} onClick={(e)=>previewRating("18Rating",appStatus.bigViewPic)}>18</button>
      <button className={`overlay ${(appStatus.previewPictureStats[appStatus.bigViewPic].rating === 16 ? "bg-blue-600 text-white" : "bg-gray-200 text-black0")} rounded-b-3xl absolute bottom-[4vh] left-[25vw] h-[10vh] w-[10vw] text-7xl`} onClick={(e)=>previewRating("16Rating",appStatus.bigViewPic)}>16</button>
      <button className={`overlay ${(appStatus.previewPictureStats[appStatus.bigViewPic].previewPic === 18 ? "bg-gray-700 text-white" : "bg-gray-200 hover:bg-gray-400")} rounded-b-3xl absolute bottom-[4vh] left-[45vw] h-[10vh] w-[10vw] text-7xl`} onClick={(e)=>previewRating("18Preview",appStatus.bigViewPic)}>P18</button>
      <button className={`overlay ${(appStatus.previewPictureStats[appStatus.bigViewPic].previewPic === 16 ? "bg-gray-700 text-white" : "bg-gray-200 hover:bg-gray-400")} rounded-b-3xl absolute bottom-[4vh] left-[55vw] h-[10vh] w-[10vw] text-7xl`} onClick={(e)=>previewRating("16Preview",appStatus.bigViewPic)}>P16</button>
    </div>;
  }
  // Closes the Overview
  function closeOverview(){
    setAppStatus({
      ...appStatus,
      bigViewMode : false
    });
  }
  // Attaches the Eventlistener for BigPreviewPicture
  useEffect(()=>{
    function changePic(e){
      if(appStatus.bigViewMode){
        if(e.repeat){
          return;
        }
        if(e.key === "ArrowLeft"){
          e.preventDefault();
          prevImage();
        }else if(e.key === "ArrowRight"){
          e.preventDefault();
          nextImage();
        }else if(e.key === "1"){
          e.preventDefault();
          previewRating("18Rating",appStatus.bigViewPic)
        }else if(e.key === "2"){
          e.preventDefault();
          previewRating("16Rating",appStatus.bigViewPic)
        }else if(e.key === "Escape"){
          closeOverview();
        }
      }
    }
    window.addEventListener("keydown",changePic);
    return () => {window.removeEventListener("keydown",changePic)}
  });
  return(
    <div className="absolute top-[11vh] w-full h-[89vh] text-[15px]">
      <VideoField vid={vid} setVid={setVid} vidRef={vidRef} appStatus={appStatus} setAppStatus={setAppStatus} setAllPreviews={setAllPreviews}/>
      <Flags appStatus={appStatus} setAppStatus={setAppStatus} vid={vid}/>
      {(!(["53_1","53_2","53_4","53_9"].includes(vid.video.type)) && <TrailerField vid={vid} appStatus={appStatus} setAppStatus={setAppStatus}/>)}
      <PreviewField vid={vid} appStatus={appStatus} setAppStatus={setAppStatus} previewRating={previewRating}/>
      <TagField vid={vid} setVid={setVid} appStatus={appStatus}/>
      <NewUserInfo pos={"absolute top-[0vh] left-[64vw] h-[19vh] w-[35vw]"} maxTextHeight={"h-[9vh]"} acc={vid.account} showOrigin={false}/>
      <Description vid={vid} setVid={setVid} appStatus={appStatus} setAppStatus={setAppStatus}/>
      <DocumentField appStatus={appStatus} setAppStatus={setAppStatus} vid={vid}/>
      <ButtonField appStatus={appStatus} setAppStatus={setAppStatus} vid={vid} skipAlbum={skipAlbum} previousAlbum={previousAlbum} rateVideo={rateVideo} setAllPreviews={setAllPreviews} doDelay={doDelay} doRetranscode={doRetranscode}/>
      <SearchAndFilter vid={vid} appStatus={appStatus} setAppStatus={setAppStatus}/>
      {(appStatus.rejectOverlay && <RejectOverlay reasons={vid.allowedRejectionReasons} appStatus={appStatus} setAppStatus={setAppStatus} oc={rejectVideo}/>)}
      {pictureOverlay}
    </div>
  );
}

// Komponente in der die Buttons am unteren Bildrand enthalten sind zum raten, skippen, ablehnen,...
function ButtonField({appStatus,setAppStatus,vid,skipAlbum,previousAlbum,rateVideo,setAllPreviews,doDelay,doRetranscode}){
  // Opens the Overlay for the Rejectreasons
  function openRejectReasons(){
    if(vid.isButtonRejectAllowed){
      setAppStatus({
        ...appStatus,
        rejectOverlay : true
      });
    }
  }
  // Selects a FSK Rating for the Video
  function setRating(val){
    setAllPreviews(val);
    setAppStatus({
      ...appStatus,
      selectedRating : val
    });
  }
  return(
    <div className="absolute left-[0.5vw] bg-white dark:bg-slate-700 rounded-3xl p-2 top-[82.5vh] grid grid-cols-8 w-[98.5vw] my-1 gap-5">
      <button className={`py-1 bg-lime-600 text-white rounded-full font-bold`} onClick={rateVideo}>{"Rate Video"}</button>
      <button className={`py-1 ${(sessionStorage.getItem("previous")!==null?"text-zanBlue border-zanBlue bg-white":"text-disabledGrey border-disabledGrey")} border-2 rounded-full font-bold`} onClick={()=>previousAlbum()}>{"Previous Video"}</button>
      <button className={`py-1 ${(vid.isButtonSkipAllowed || window.location.pathname !== "/videoRating" ? "text-zanBlue  border-zanBlue ":"text-disabledGrey border-disabledGrey")} bg-white border-2 rounded-full font-bold`} onClick={skipAlbum}>{"Skip Video"}</button>
      <button className={`py-1 ${(appStatus.selectedRating===18 ? "bg-zanRed text-white" : "bg-white text-zanRed")} border-zanRed border-2 rounded-full font-bold`} onClick={(e)=>setRating(18)}>{"FSK 18"}</button>
      <button className={`py-1 ${(appStatus.selectedRating===16 ? "bg-zanBlue text-white" : "bg-white text-zanBlue")} border-zanBlue border-2 rounded-full font-bold`} onClick={(e)=>setRating(16)}>{"FSK 16"}</button>
      <button className={`py-1 ${(vid.isButtonRetranscodeAllowed?"text-zanBlue border-zanBlue":"text-disabledGrey border-disabledGrey")} bg-white border-2 text-black rounded-full font-bold`} onClick={()=>doRetranscode()}>{"Retranscode Video"}</button>
      <button className={`py-1 ${(vid.isButtonDelayAllowed?"text-zanBlue border-zanBlue":"text-disabledGrey border-disabledGrey")} bg-white border-2 rounded-full font-bold`} onClick={()=>doDelay()}>{"Delay Video"}</button>
      <button className={`py-1 ${(vid.isButtonRejectAllowed?"text-white bg-zanRed":"text-disabledGrey border-disabledGrey border-2")} rounded-full font-bold`} onClick={openRejectReasons}>{"Reject Video"}</button>
    </div>
  );
}

// Die Komponente in der die Dokumente der Models angezeigt werden und das Feld mit AlbumID obendrüber
// Es werden nur maximal Dokumente von 4 Models angezeigt. Sind es mehr ist am Ende der Dokumentenliste ein Button
// mit dem die restlichen Dokumente nachgeladen werden. Da manche Models an die 100 Personen registriert haben
// dient dies der Performance der Webseite
function DocumentField({appStatus,setAppStatus,vid}){
  const [showSome,setShowSome] = useState((vid.account.documents.length > 4));
  let idShots = []
  if(showSome){
    idShots = vid.account.documents.slice(0,4).map((id) => <PersonDocuments key={id.userId} uid={id.userId} first={id.firstname} last={id.lastname} pics={id.urls}/>);
  }else{
    idShots = vid.account.documents.map((id) => <PersonDocuments key={id.userId} uid={id.userId} first={id.firstname} last={id.lastname} pics={id.urls}/>);
  }

  function changePremium(){
    setAppStatus({
      ...appStatus,
      premiumOnly : !appStatus.premiumOnly
    })
  }

  return(
    <>
      <div className="absolute top-[20vh] left-[64vw] h-[10vh] w-[35vw] p-2 bg-white dark:bg-gray-700 rounded-3xl">
        <p className="ml-5"><b>Album ID:</b> {vid.video.albumId} | <b>VideoID:</b> {vid.video.videoId} | </p>
        <p className="ml-5"><b>Uploaded:</b> {vid.video.createDate.substring(0,10)} | <b>Planned release date:</b> {(vid.video.plannedReleaseDate===""?"Immediately":vid.video.plannedReleaseDate.substring(0,10))}</p>
        <div className="absolute bottom-2 left-5">
          <img className="inline" alt="" src={appStatus.premiumOnly ? "/icons/check-circle.png" : "/icons/icon-base.png"} onClick={()=>changePremium()}></img>
          <p className="inline ml-2 text-sm">Premium Only</p>
        </div>
        <div className="absolute bottom-2 left-44">
          <p className="text-black"><span className="text-disabledGreyText">{(vid.video.lastRatingName===""?"":"Last rated by: ")}</span>{vid.video.lastRatingName+" "+vid.video.lastRatingDate.substring(0,10) + " " + vid.video.lastRatingDate.substring(11,16)}</p>
        </div>
      </div>  
      <div className="absolute top-[31vh] left-[64vw] h-[51vh] w-[35vw] p-5 bg-white dark:bg-gray-700 rounded-3xl">
          <div className="overflow-y-scroll h-[48vh]">
            {idShots}
            {(showSome && <button onClick={()=>{setShowSome(false)}} className="bg-zanBlue text-white mt-2 p-2 w-full rounded-full">Load all Documents</button>)}
          </div>
      </div>
    </>
  );
}

// Komponente die die Dokumente pro Model beinhaltet.
function PersonDocuments({uid,first,last,pics}){
  let pp = pics.map(p => <a href={p} key={p} target="_blank" rel="noreferrer"><img key={p} alt="idshot" src={p}></img></a>);
  let displayedName = "";
  if(first === "" || last === ""){
    displayedName = uid;
  }else{
    displayedName = first + " " + last;
  }
  return(
    <div>
      <p className="bg-disabledGreyText text-white pl-2">{displayedName}</p>
      <div className="grid grid-cols-2">
        {pp}
      </div>
    </div>
  );
}

// Komponente in der das Video beinhaltet wird und Informationen wie Typ und Preis geändert werden können
function VideoField({vid,setVid,vidRef,appStatus,setAppStatus,setAllPreviews}){
  // Aktualisert nach dem Laden des Videos den maximal zulässigen Endpunkt für die Trailer
  function setMaxDur(e){
    setAppStatus({
      ...appStatus,
      maxDuration : e.target.duration
    });
  }

  function changeMaxPrice(e){
    if(Number(e.target.value) >= Number(vid.video.price)){
      setVid({
        ...vid,
        video : {
          ...vid.video,
          maxPrice : e.target.value
        }
      });
    }
  }

  function changePrice(e){
    let mp = vid.video.maxPrice;
    if(vid.video.maxPrice > 20){
      mp = e.target.value;
    }
    if(Number(e.target.value) <= Number(vid.video.maxPrice)){
      setVid({
        ...vid,
        video : {
          ...vid.video,
          price : e.target.value,
          maxPrice : mp
        }
      });
    }
  }

  function changeVideoType(e){
    let c = "";
    if((e.target.value === "53_4")){     // contest Videos
      c = (vid.allowedContests.length > 0 ? vid.allowedContests[0].id : "");
    }
    setAppStatus({
      ...appStatus,
      selectedContest : c
    });
    setVid({
      ...vid,
      video : {
        ...vid.video,
        typeName : vid.allowedVideoTypes.find(i => i.id === e.target.value).label,
        type : e.target.value
      }
    });
  }

  // Wechselt die Videoquelle auf eine mit einer anderen Auflösung
  function changeVideoSource(e){
    setAppStatus({
      ...appStatus,
      videoSource : e.target.value
    });
    vidRef.current.load();
  }

  function changeContest(e){
    setAppStatus({
      ...appStatus,
      selectedContest : e.target.value
    });
  }

  function setNewMaxPrice(){
    let n = Number(appStatus.newMaxPrice);
    if(n>99.99){
      n = 99.99;
    }else if(n<=0){
      n = 0;
    }else{
      if((n*100)%100===0){
        n -= 0.01;
      }else if((n*100)%100>=1 && (n*100)%100<=49){
        n = Math.floor(n)+0.49;
      }else{
        n = Math.floor(n)+0.99;
      }
    }
    setVid({
      ...vid,
      video : {
        ...vid.video,
        price : n,
        maxPrice : n
      }
    });
  }

  function onChangeNewMaxPrice(e){
    setAppStatus({
      ...appStatus,
      newMaxPrice : e.target.value
    });
  }

  // Alle vorhandenen Preise mit abstufungen. Wird mithilfe von maxPrice gecappt und als Auswahl für den Preis gegeben
  let mp = vid.video.priceRange;
  if(!mp.includes(Number(vid.video.price))){
    mp.push(Number(vid.video.price));
  }
  //mp.splice(mp.indexOf(vid.video.maxPrice)+1,Infinity);
  let cp = [...mp];
  mp = mp.map((item,index) => <option key={index} value={item}>{item+" €"}</option>); 
  cp = cp.map((item,index) => <option key={index} value={item}>{item+" €"}</option>);

  // Hier werden die optionen für die select-Objekte generiert
  let sources = vid.video.urls.map((i) => <option key={i.format} value={i.format}>{i.format}</option>);
  let vidTypeOptions = vid.allowedVideoTypes.map(t => <option value={t.id} key={t.id}>{t.label}</option>);
  let contestOptions = vid.allowedContests.map(t => <option value={t.id} key={t.id}>{t.label}</option>);

  let contestSelect = null;
  if(vid.video.type === "53_4"){  // Contestvideo Type
    contestSelect = <div className="absolute top-[40vh] left-28">
                      <p className="inline">Aktion: </p>
                      <select className="inline bg-disabledGrey rounded-full text-black h-[26px] w-56" value={appStatus.selectedContest} onChange={(e)=>changeContest(e)}>
                        {contestOptions}
                      </select>
                    </div>
  }

  let priceSelect = null;
  if(vid.video.type === "53_3" || vid.video.type === "53_4"){   // Private Shop or Contestvideos
    priceSelect = <>
                    <div className="absolute top-[34vh] left-5">
                      <p className="inline font-bold">Set new max price:</p>
                      <input className="inline w-16 bg-disabledGrey rounded-full text-black ml-1 text-center h-[26px]" type={"number"} min="0" max="100" value={appStatus.newMaxPrice} onChange={(e)=>onChangeNewMaxPrice(e)}></input>
                      <button className="inline text-zanBlue w-14" onClick={()=>setNewMaxPrice()}>Apply</button>
                    </div>
                    <div className="absolute top-[37vh] left-5">
                      <p className="inline font-bold">Max. Preis: </p>
                      <select className="inline bg-disabledGrey rounded-full text-black ml-1 h-[26px]" value={vid.video.maxPrice} onChange={(e)=>changeMaxPrice(e)}>
                        {mp}
                      </select>
                      <p className="inline ml-3">Preis: </p>
                      <select className="inline bg-disabledGrey rounded-full text-black ml-1 h-[26px]" value={vid.video.price} onChange={(e)=>changePrice(e)}>
                        {cp}
                      </select>
                      <p className="inline ml-1 text-[13px]">Discount: 0%</p>
                    </div>
                  </>
  }

  return(
    <div className={`absolute top-0 left-[0.5vw] h-[43vh] w-[26vw] rounded-3xl p-4 ${appStatus.selectedRating === 18 ? "bg-white" : "bg-thoBlue"}`}>
      <video className="w-[24vw] h-[28vh] mx-auto bg-black" ref={vidRef} id="vid" onLoadedMetadata={(e)=>setMaxDur(e)} autoPlay controls>
        <source src={vid.video.urls.find(v=>v.format===appStatus.videoSource).url}></source>
      </video>
      <div className="absolute top-[30.5vh] left-5">
        <p className="inline font-bold">Type: </p>
        <select className="inline bg-disabledGrey rounded-full px-2 h-[26px] text-black" value={vid.video.type} onChange={(e)=>changeVideoType(e)}>
          {vidTypeOptions}
        </select>
        <select className="inline bg-disabledGrey rounded-full px-2 h-[26px] text-black mx-1" value={appStatus.videoSourceName} defaultValue={"Original"} onChange={(e)=>changeVideoSource(e)}>
          {sources}
        </select>
      </div>
      {priceSelect}
      {contestSelect}
      <button className="absolute right-5 top-[30.5vh] bg-disabledGrey text-black rounded-full px-2 h-[26px]" onClick={(e)=>setAllPreviews(18)}>Set all FSK 18</button>
      <button className="absolute right-5 top-[33.5vh] bg-disabledGrey text-black rounded-full px-2 h-[26px]" onClick={(e)=>setAllPreviews(16)}>Set all FSK 16</button>
      {vid.account.isPremiumCooperation && <p className="absolute left-0 bottom-0 rounded-bl-3xl rounded-tr-lg bg-orange-400 border-[3px] border-orange-400 px-2 font-bold text-[14px] text-white">PREMIUM</p>}
      {vid.account.isNew && <p className="absolute right-0 bottom-0 rounded-br-3xl rounded-tl-lg bg-orange-400 border-[3px] border-orange-400 px-2 font-bold text-[14px] text-white">NEW MODEL</p>}
    </div>
  );
}

// Die komponente in denen die Flags beinhaltet sind
function Flags({appStatus,setAppStatus,vid}){

  // Setzt ein Flag
  function setFlag(flag){
    let f = appStatus.selectedFlags;
    if(!f.includes(flag)){
      f.push(flag);
    }
    setAppStatus({
      ...appStatus,
      selectedFlags : f
    });
  }

  // Entfernt ein Flag
  function remFlag(flag){
    let f = appStatus.selectedFlags;
    if(f.includes(flag)){
      f.splice(f.indexOf(flag),1);
    }
    setAppStatus({
      ...appStatus,
      selectedFlags : f
    });
  }

  // Tauscht den Status eines Flags
  function toggleFlag(flag){
    let f = appStatus.selectedFlags;
    if(f.includes(flag)){
      f.splice(f.indexOf(flag),1);
    }else{
      f.push(flag);
    }
    setAppStatus({
      ...appStatus,
      selectedFlags : f
    });
  }

  // Diese Funktion wird beim drücken eines Flags aufgerufen. Viele Flags haben besonderheiten da sie andere Flags (de)aktivieren oder sich in bestimmten
  // konstellationen nicht aktivieren lassen wollen. Das Verhalten wurde aus dem alten Rating Tool kopiert. 
  function modifyFlag(name){
    switch(name){
      case "thirdParty": remFlag("welcomeclip_approved"); toggleFlag("third_paty"); break;
      case "visitx": toggleFlag("vx_branded"); break;
      case "ns":
        if (appStatus.selectedFlags.includes("ns")){
          remFlag("ns");
          remFlag("fetish");
          remFlag("shadow_ban");
        }else{
          setFlag("ns");
          setFlag("fetish");
          setFlag("shadow_ban");
          remFlag("welcomeclip_approved");
        }
        break;
      case "welcomeclip":
        if(appStatus.selectedFlags.includes("third_paty") || appStatus.selectedFlags.includes("vxpages_only") || appStatus.selectedFlags.includes("ns")){
          return;
        }
        toggleFlag("welcomeclip_approved"); break;
      case "premium": 
        if(appStatus.audioQuality !== 1 && !appStatus.selectedFlags.includes("ns")){
          toggleFlag("premium_content");
        } break;
      case "aktion365": toggleFlag("calendar365"); break;
      case "fetish": toggleFlag("fetish"); break;
      case "shadowBan": toggleFlag("shadow_ban"); break;
      case "music":
        if(vid.account.hasGemaProof){
          setAppStatus({
            ...appStatus,
            audioQuality : 2
          });
        }
        break;
      case "noSound":
        remFlag("premium_content");
        setAppStatus({
          ...appStatus,
          audioQuality : 1
        });
        break;
      case "noise":
        setAppStatus({
          ...appStatus,
          audioQuality : 0
        });
        break;
      default:
    }
  }
    

  return(
    <div className={`absolute top-[44vh] left-[0.5vw] h-[21vh] w-[26vw] bg-white dark:bg-gray-700 rounded-3xl`}>
      <div className="absolute left-5 top-[2vh] inline">
        <p className="inline font-bold">Flags:</p>
        <button className={`h-[3.5vh] w-[6vw] absolute left-[5vw] top-0 border-2 rounded-full ${(appStatus.selectedFlags.includes("third_paty") ?"bg-zanBlue text-white border-zanBlue":"bg-disabledGrey text-black border-disabledGrey")}`} onClick={()=>modifyFlag("thirdParty")}>3rd Party</button>
        <button className={`h-[3.5vh] w-[6vw] absolute left-[11.5vw] top-0 border-2 rounded-full ${(appStatus.selectedFlags.includes("vx_branded")?"bg-zanBlue text-white border-zanBlue":"bg-disabledGrey text-black border-disabledGrey")}`} onClick={()=>modifyFlag("visitx")}>Visit-X</button>
        <button className={`h-[3.5vh] w-[6vw] absolute left-[18vw] top-0 border-2 rounded-full ${(appStatus.selectedFlags.includes("ns")?"bg-zanBlue text-white border-zanBlue":"bg-disabledGrey text-black border-disabledGrey")}`} onClick={()=>modifyFlag("ns")}>NS</button>
      </div>
      <div className="absolute left-5 top-[6vh]">
        <p className="inline font-bold">Ton:</p>
        <button className={`h-[3.5vh] w-[6vw] absolute left-[5vw] top-0 border-2 rounded-full ${(appStatus.audioQuality===2?"bg-zanBlue text-white border-zanBlue":`bg-disabledGrey text-black border-disabledGrey`)}`} onClick={()=>modifyFlag("music")}>Musik</button>
        <button className={`h-[3.5vh] w-[6vw] absolute left-[11.5vw] top-0 border-2 rounded-full ${(appStatus.audioQuality===1?"bg-zanBlue text-white border-zanBlue":"bg-disabledGrey text-black border-disabledGrey")}`} onClick={()=>modifyFlag("noSound")}>kein Ton</button>
        <button className={`h-[3.5vh] w-[6vw] absolute left-[18vw] top-0 border-2 rounded-full ${(appStatus.audioQuality===0?"bg-zanBlue text-white border-zanBlue":"bg-disabledGrey text-black border-disabledGrey")}`} onClick={()=>modifyFlag("noise")}>Geräusche</button>
      </div>
      <div className="absolute left-5 top-[10vh]">
        <p className="inline font-bold">Geeignet als:</p>
        <button className={`h-[3.5vh] w-[9vw] absolute left-[5vw] top-0 border-2 rounded-full ${(appStatus.selectedFlags.includes("welcomeclip_approved")?"bg-zanBlue text-white border-zanBlue":"bg-disabledGrey text-black border-disabledGrey")}`} onClick={()=>modifyFlag("welcomeclip")}>Welcomeclip</button>
        <button className={`h-[3.5vh] w-[9vw] absolute left-[15vw] top-0 border-2 rounded-full ${(appStatus.selectedFlags.includes("premium_content")?"bg-zanBlue text-white border-zanBlue":"bg-disabledGrey text-black border-disabledGrey")}`} onClick={()=>modifyFlag("premium")}>Premium Content</button>
      </div>
      <div className="absolute left-5 top-[14vh]">
        <p className="inline font-bold">Aktion Flags:</p>
        <button className={`h-[3.5vh] w-[6vw] absolute left-[5vw] top-0 border-2 rounded-full ${(appStatus.selectedFlags.includes("calendar365")?"bg-zanBlue text-white border-zanBlue":"bg-disabledGrey text-black border-disabledGrey")}`} onClick={()=>modifyFlag("aktion365")}>Aktion 365</button>
        <button className={`h-[3.5vh] w-[6vw] absolute left-[11.5vw] top-0 border-2 rounded-full ${(appStatus.selectedFlags.includes("fetish")?"bg-zanBlue text-white border-zanBlue":"bg-disabledGrey text-black border-disabledGrey")}`} onClick={()=>modifyFlag("fetish")}>Fetisch</button>
        <button className={`h-[3.5vh] w-[6vw] absolute left-[18vw] top-0 border-2 rounded-full ${(appStatus.selectedFlags.includes("shadow_ban")?"bg-zanBlue text-white border-zanBlue":"bg-disabledGrey text-black border-disabledGrey")}`} onClick={()=>modifyFlag("shadowBan")}>Shadow ban</button>
      </div>
    </div>
  );
}

// In dieser Komponente werden die Start- und Endzeiten für die Trailer gesetzt.
function TrailerField({vid,appStatus,setAppStatus}){

  // Setzt einen eingetippten Wert ein
  function setTime(e,which){
    let a = e.target.value;
    let b = new Date(appStatus.maxDuration*1000).toLocaleTimeString('de',{ timeZone: 'UTC' })
    if(a<=b){
      switch(which){
        case "18S": setAppStatus({...appStatus,trailerFSK18Start : e.target.value}); break;
        case "18E": setAppStatus({...appStatus,trailerFSK18End : e.target.value}); break;
        case "16S": setAppStatus({...appStatus,trailerFSK16Start : e.target.value}); break;
        case "16E": setAppStatus({...appStatus,trailerFSK16End : e.target.value}); break;
        default:
      }
    }
  }

  // Mit dieser Funktion wird die aktuelle Zeit aus dem Player übernommen.
  // Bei Startzeiten wird das Ende automatisch 15s später gesetzt. Bei Endzeiten bleibt der Startzeitpunkt unverändert
  function setTimeFromPlayer(which){
    // Macht aus 2.42342 Sekunden => 00:00:02
    let ct = Math.floor(document.getElementById("vid").currentTime);
    let ctString = new Date(ct*1000).toLocaleTimeString('de',{ timeZone: 'UTC' });
    let ctString2 = new Date((ct+15)*1000).toLocaleTimeString('de',{ timeZone: 'UTC' });
    if(convertTimeStringToInt(ctString2) > appStatus.maxDuration){
      ctString2 = new Date(appStatus.maxDuration*1000).toLocaleTimeString('de',{ timeZone: 'UTC' });
    }
    switch(which){
      case "18S": setAppStatus({...appStatus,trailerFSK18Start : ctString,trailerFSK18End: ctString2}); break;
      case "18E": setAppStatus({...appStatus,trailerFSK18End: ctString}); break;
      case "16S": setAppStatus({...appStatus,trailerFSK16Start : ctString,trailerFSK16End: ctString2}); break;
      case "16E": setAppStatus({...appStatus,trailerFSK16End: ctString}); break;
      default:
    }
  }

  // Spielt den Trailer mit der entsprechenden länge ab. Dafür wird eine entsprechende Zeit gewartet und das Video danach pausisert.
  function playTrailer(age){
    if(videoValidator(appStatus,vid,setAppStatus,false)){
      let v = document.getElementById("vid");
      let to = 0;
      if(age === 18){
        v.currentTime = convertTimeStringToInt(appStatus.trailerFSK18Start);
        to = convertTimeStringToInt(appStatus.trailerFSK18End) - convertTimeStringToInt(appStatus.trailerFSK18Start);
      }else{
        v.currentTime = convertTimeStringToInt(appStatus.trailerFSK16Start);
        to = convertTimeStringToInt(appStatus.trailerFSK16End) - convertTimeStringToInt(appStatus.trailerFSK16Start);
      }

      if(to>0){ // Falls der Trailer min. 1 sek lang ist
        if(v.paused){
          v.play();
        }
        let timeout = setTimeout(pauseVid,1000+(to*1000));
        if(appStatus.currentPlayback !== null){
          clearTimeout(appStatus.currentPlayback);
        }
        setAppStatus({
          ...appStatus,
          currentPlayback: timeout});
      }
    }
  }

  // Für das automatische Pausieren des Trailers verantwortlich
  function pauseVid(){
    let v = document.getElementById("vid");
    if(!v.paused){
      v.pause();
    }
  }

  // Falls kein FSK 16 Trailer gesetzt werden kann werden die Zeiten für diesen gelöscht
  function noFsk16Trailer(){
    let ts = appStatus.trailerFSK16Start;
    let te = appStatus.trailerFSK16End;
    if(!appStatus.trailerFSK16Disabled){
      ts = "00:00:00";
      te = "00:00:00";
    }
    setAppStatus({
      ...appStatus,
      trailerFSK16Disabled : !appStatus.trailerFSK16Disabled,
      trailerFSK16Start : ts,
      trailerFSK16End : te
    });
  }

  return(
    <div className={`absolute top-[66vh] left-[0.5vw] h-[16vh] w-[26vw] bg-white dark:bg-gray-700 rounded-3xl`}>
      <div className="absolute top-2 w-full">
        <p className="absolute left-5 top-3 w-20 font-bold leading-5">{"FSK-18 Trailer:"}</p>
        <p className="absolute left-28 top-3">Start:</p>
        <input className="absolute left-[150px] top-3 pl-2 rounded-full bg-disabledGrey text-black h-[24px]" type={"time"} step={1} value={appStatus.trailerFSK18Start} onChange={(e)=>setTime(e,"18S")}></input>
        <button className="absolute left-36 top-[38px] text-xs hover:text-blue-800" onClick={(e)=>setTimeFromPlayer("18S")}>Take time from Player</button>
        <p className="absolute left-[270px] top-3">End:</p>
        <input className="absolute left-[302px] top-3 pl-2 rounded-full bg-disabledGrey text-black h-[24px]" type={"time"} step={1} value={appStatus.trailerFSK18End} onChange={(e)=>setTime(e,"18E")}></input>
        <button className="absolute left-[282px] top-[38px] text-xs hover:text-blue-800" onClick={(e)=>setTimeFromPlayer("18E")}>Take time from Player</button>
        <button className="absolute left-[440px] top-3" onClick={(e)=>playTrailer(18)}>
          <img src="/icons/play.png" alt="" className="h-7 w-7 inline -translate-y-0.5"></img>
        </button>
      </div>
      <div className="absolute top-2 w-full">
        <p className="absolute left-5 top-16 w-20 font-bold leading-5">{"FSK-16 Trailer:"}</p>
        <p className="absolute left-28 top-16">Start:</p>
        <input className="absolute left-[150px] top-16 pl-2 rounded-full bg-disabledGrey text-black h-[24px]" type={"time"} step={1} value={appStatus.trailerFSK16Start} onChange={(e)=>setTime(e,"16S")} disabled={appStatus.trailerFSK16Disabled}></input>
        <button className="absolute left-36 top-[88px] text-xs hover:text-blue-800" onClick={(e)=>setTimeFromPlayer("16S")}>Take time from Player</button>
        <p className="absolute left-[270px] top-16">End:</p>
        <input className="absolute left-[302px] top-16 pl-2 rounded-full bg-disabledGrey text-black h-[24px]" type={"time"} step={1} value={appStatus.trailerFSK16End} onChange={(e)=>setTime(e,"16E")} disabled={appStatus.trailerFSK16Disabled}></input>
        <button className="absolute left-[282px] top-[88px] text-xs" onClick={(e)=>setTimeFromPlayer("16E")}>Take time from Player</button>
        <button className="absolute left-[440px] top-16" onClick={(e)=>playTrailer(16)}>
          <img src="/icons/play.png" alt="" className="h-7 w-7 inline -translate-y-0.5"></img>
        </button>
      </div>
      <div className="absolute left-32 bottom-2">
        <input className="" id="no16Prev" type={"checkbox"} onChange={(e)=>noFsk16Trailer()} checked={appStatus.trailerFSK16Disabled}></input>
        <label className="pl-1 text-[13px]" htmlFor="no16Prev">No softcore preview available</label>
      </div>
    </div>
  );
}

// In dieser Komponente sind die Vorschaubilder gelistet
function PreviewField({vid,appStatus,setAppStatus,previewRating}){
  let imgs = vid.video.pictures;
  imgs = imgs.map((img,index) => <PreviewImage key={index} src={img.url} appStatus={appStatus} setAppStatus={setAppStatus} ownIndex={index} previewRating={previewRating}/>);
  return(
  <div className={`absolute top-0 left-[27vw] h-[47vh] w-[36.5vw] p-4 rounded-3xl ${appStatus.selectedRating === 18 ? "bg-white" : "bg-thoBlue"}`}>
    <div className="grid gap-1 grid-cols-4 grid-flow-row w-[35.5vw] h-[45vh] overflow-y-scroll">
      {imgs}
    </div>
  </div>
  );
}

// Diese Komponente ist ein einzelnes Vorschaubild mit den Buttons darunter
function PreviewImage({src,appStatus,setAppStatus,ownIndex,previewRating}){
  let ico = null;
  if(appStatus.previewPictureStats[ownIndex].previewPic === 18){
    ico = <div className="absolute top-0 right-0 w-6 h-5 rounded-b-lg bg-red-500"><p className="absolute top-0 right-0 w-6 h-5 text-white font-bold text-center text-[13px]">18</p></div>
  }else if(appStatus.previewPictureStats[ownIndex].previewPic === 16){
    ico = <div className="absolute top-0 right-0 w-6 h-5 rounded-b-lg bg-zanBlue"><p className="absolute top-0 right-0 w-6 h-5 text-white font-bold text-center text-[13px]">16</p></div>
  }

  // Mit einem klick auf das Bild wird die große Ansicht geöffnet
  function openOverview(){
    setAppStatus({
      ...appStatus,
      bigViewMode : true,
      bigViewPic : ownIndex
    });
  }
  return(
    <div className="w-[8vw] h-[11vh] relative">
      <img className="object-scale-down w-[8vw] h-[9vh] left-[0.5vw] absolute bg-white rounded-lg" src={src} alt="Vorschaubild" onClick={openOverview}></img>
      {ico}
      <button className={`absolute w-[1.8vw] h-[2vh] left-[0.5vw] -bottom-[1px] rounded-full ${(appStatus.previewPictureStats[ownIndex].rating === 18? "bg-red-500 text-white": "bg-gray-200 text-black")}`} onClick={(e)=>previewRating("18Rating",ownIndex)}>
        <p className="text-center -translate-y-0.0 text-[13px]">18</p>
      </button>
      <button className={`absolute w-[1.8vw] h-[2vh] left-[2.5vw] -bottom-[1px] rounded-full ${(appStatus.previewPictureStats[ownIndex].rating === 16? "bg-zanBlue text-white": "bg-gray-200 text-black")}`} onClick={(e)=>previewRating("16Rating",ownIndex)}>
       <p className="text-center -translate-y-0.0 text-[13px]">16</p>
      </button>
      <button className={`absolute w-[1.8vw] h-[2vh] left-[4.5vw] -bottom-[1px] rounded-full ${(appStatus.previewPictureStats[ownIndex].previewPic === 18? "bg-gray-900 text-white": "bg-gray-200 text-black")}`} onClick={(e)=>previewRating("18Preview",ownIndex)}>
        <p className="text-center -translate-y-0.0 text-[13px]">P18</p>
      </button>
      <button className={`absolute w-[1.8vw] h-[2vh] left-[6.5vw] -bottom-[1px] rounded-full ${(appStatus.previewPictureStats[ownIndex].previewPic === 16? "bg-gray-900 text-white": "bg-gray-200 text-black")}`} onClick={(e)=>previewRating("16Preview",ownIndex)}>
       <p className="text-center -translate-y-0.0 text-[13px]">P16</p>
      </button>
    </div>
  );
}

// In dieser Komponente werden die Tags für das Video gelistet
function TagField({vid,setVid,appStatus}){
  const [newTag,setNewTag] = useState("");

  // Adds one or more entered tags seperated by ,
  function addTag(){
    if(newTag.trim() === ''){
      return;
    }
    let t_old = vid.video.allTags;
    let n_old = vid.video.newTags;
    let t_add = newTag.split(',');
    for (let e in t_add){
      if(!t_old.includes(t_add[e].trim()) && !(t_add[e].trim() === "")){
        t_old.push(t_add[e].trim());
        n_old.push(t_add[e].trim());
      }
    }
    setVid({
      ...vid,
      video : {
        ...vid.video,
        allTags : t_old,
        newTags : n_old
      }
    });
    setNewTag("");
  }

  // Deletes a single tag
  function removeTag(txt){
    let t = vid.video.allTags;
    let n = vid.video.newTags;
    t.splice(vid.video.allTags.indexOf(txt),1);
    setVid({
      ...vid,
      video : {
        ...vid.video,
        allTags : t,
        newTags : n
      }
    });
  }
  // Eventhandler for editing the to-be-added-tag
  function onChangeT(val){
    setNewTag(val);
  }
  // Generates the list of tags
  var tagList;
  if (vid.video.allTags.length > 0){
    tagList = vid.video.allTags.map((t,index) => <Tag key={index} text={t} suspected={vid.video.suspectedWords} newTags={vid.video.newTags} oc={removeTag}/>);
  }else{
    tagList = "";
  }
  // Adds the keydownHandler for Enter
  useEffect(()=>{
    let e = document.getElementById("tagInputField");
    function checkKey(e){
      if(e.repeat){
        return;
      }else if(e.key === "Enter"){
        addTag();
      }
    }
    e.addEventListener("keydown",checkKey);
    return () => {e.removeEventListener("keydown",checkKey)}
  });
  
  return(
    <div className="absolute top-[66vh] left-[27vw] h-[16vh] w-[36.5vw] bg-white dark:bg-gray-700 rounded-3xl">
      <div className="absolute top-0 left-0 h-10 w-full">
        <input id="tagInputField" className="rounded-full bg-disabledGrey text-black m-4 py-1 px-3 text-[15px]" type="text" size="30" value={newTag} placeholder="Insert tags" onChange={(e) => {onChangeT(e.target.value)}}></input>
        <button className="text-zanBlue my-2 w-7 h-7 inline-block align-middle text-[15px]" onClick={addTag}>
          Apply
        </button>
      </div>
      <div className="absolute top-14 left-4 h-full w-[35vw]">
        <div className="h-[9vh] overflow-y-scroll">
          <ul className="">
            {tagList}
          </ul>
        </div>
      </div>
    </div>
  );
}

// In dieser Komponente sind weitere Komponenten zur Anzeige der Videobeschreibung enthalten
function Description({vid,setVid,appStatus,setAppStatus}){
  const [foundwords,setFoundwords] = useState([]);
  // Changing the displayed language
  function changeLanguage(val){
    setAppStatus({
      ...appStatus,
      descriptionLanguage : val
    });
  }
  // Eventhandler for Title editing
  function onChangeTi(val){
    if(vid.video.titles.find(i=>i.language===appStatus.descriptionLanguage)!==undefined){
      let d = vid.video.titles;
      d.find(i=>i.language===appStatus.descriptionLanguage).text = val;
      setVid({
        ...vid,
        video: {
          ...vid.video,
          titles : d
        }
      });
    }else{

    }
  }
  // Eventhandler for Text editing
  function onChangeTe(val){
    if(vid.video.descriptions.find(i=>i.language===appStatus.descriptionLanguage)!==undefined){
      let d = vid.video.descriptions;
      d.find(i=>i.language===appStatus.descriptionLanguage).text = val;
      setVid({
        ...vid,
        video: {
          ...vid.video,
          descriptions : d
        }
      });
    }else{

    }
  }

  // Searches for Badwords
  let apti = JSON.stringify(vid.video.titles);
  let apte = JSON.stringify(vid.video.descriptions);
  useEffect(()=>{
    function applyFilters(){
      let w = [];
      let l = vid.video.suspectedWords;
      if(vid.allowedLanguages.includes("DE")){
        for(let e in l){
          if(vid.video.titles.find(t=>t.language==="DE").text.includes(l[e])){
            w.push(l[e]);
          }
        }
        for(let e in l){
          if(vid.video.descriptions.find(t=>t.language==="DE").text.includes(l[e])){
            w.push(l[e]);
          }
        }
      }
      if(vid.allowedLanguages.includes("EN")){
        for(let e in l){
          if(vid.video.titles.find(t=>t.language==="EN").text.includes(l[e])){
            w.push(l[e]);
          }
        }
        for(let e in l){
          if(vid.video.descriptions.find(t=>t.language==="EN").text.includes(l[e])){
            w.push(l[e]);
          }
        }
      }
      if(vid.allowedLanguages.includes("ES")){
        for(let e in l){
          if(vid.video.titles.find(t=>t.language==="ES").text.includes(l[e])){
            w.push(l[e]);
          }
        }
        for(let e in l){
          if(vid.video.descriptions.find(t=>t.language==="ES").text.includes(l[e])){
            w.push(l[e]);
          }
        }
      }
      for(let e in l){
        if(vid.video.allTags.includes(l[e])){
          w.push(l[e]);
        }
      }
      setFoundwords(w);
    }
    applyFilters();
  },[vid.video.suspectedWords,vid.allowedLanguages,apti,apte,vid.video.titles,vid.video.descriptions,vid.video.allTags,vid.video.allTags.length]);

  return(
    <div className="absolute top-[48vh] left-[27vw] h-[17vh] w-[36.5vw]">
      <div className={`h-full justify-items-center relative bg-white dark:bg-gray-700 rounded-3xl`}>
        <VideoDescriptionLanguageButtons vid={vid} oc={changeLanguage} appStatus={appStatus} setAppStatus={setAppStatus} badwords={(foundwords.length>0?true:false)}/>
        <VideoDescriptionTitle appStatus={appStatus} vid={vid} oc={onChangeTi} foundwords={foundwords}/>
        <VideoDescriptionText appStatus={appStatus} vid={vid} oc={onChangeTe} foundwords={foundwords}/>
      </div>
    </div>
  );
}

// Die Leiste an Elementen oben rechts. Dort kann nach Alben gesucht werden, aber auch nach alter und slots sortiert
function SearchAndFilter({vid,appStatus,setAppStatus}){
  let slots = [<option key={-1} value="-1">ALL</option>].concat(vid.allowedSlots.map((s) => <option key={s} value={s}>{s}</option>));
  const [searchAlbumValue,setSearchAlbumValue] = useState("");
  const [searchUserValue,setSearchUserValue] = useState("");

  function changeSort(e){
    setAppStatus({
      ...appStatus,
      sort : e.target.value
    });
  }
  function changeSlot(e){
    setAppStatus({
      ...appStatus,
      slot : e.target.value
    });
    sessionStorage.setItem("slot",e.target.value);
  }
  function updateAlbumSearch(e){
    setSearchAlbumValue(e.target.value);
  }
  function searchAlbum(){
    setAppStatus({
      ...appStatus,
      albumId : searchAlbumValue
    });
  }

  function updateUserSearch(e){
    setSearchUserValue(e.target.value);
  }
  function searchUser(){
    setAppStatus({
      ...appStatus,
      userId : searchUserValue
    });
  }

  return(
    <div className="absolute -top-[5.5vh] right-[0.5vw]">
      <input className="bg-zanGrey rounded-full px-3 h-[30px] text-[15px]" type="number" placeholder="search for UserID" value={searchUserValue} onChange={(e)=>updateUserSearch(e)}></input>
      <button className="text-zanBlue mx-3 text-[15px]" onClick={searchUser}>Search</button>
      <input className="bg-zanGrey rounded-full px-3 h-[30px] text-[15px]" type="number" placeholder="search for AlbumID" value={searchAlbumValue} onChange={(e)=>updateAlbumSearch(e)}></input>
      <button className="text-zanBlue mx-3 text-[15px]" onClick={searchAlbum}>Search</button>
      <p className="inline ml-3 text-[15px]">Sort:</p>
      <select className="inline mx-3 px-3 bg-zanGrey rounded-full text-black text-[15px] h-[30px]" value={appStatus.sort} onChange={(e)=>changeSort(e)}>
        <option value={true}>Newest</option>
        <option value={false}>Oldest</option>
      </select>
      <p className="inline ml-3 text-[15px]">Slot:</p>
      <select className="inline mx-3 px-3 bg-zanGrey rounded-full text-black text-[15px] h-[30px]" value={appStatus.slot} onChange={(e)=>changeSlot(e)}>
        {slots}
      </select>
    </div>
  );
}

// Buttons zur Sprachauswahl
function VideoDescriptionLanguageButtons({vid,oc,appStatus,setAppStatus,badwords}){

  const [bwColor,setBwColor] = useState("text-red-500");

  // Switches between Badwords marking and edit mode
  function toggleEdit(){
    setAppStatus({
      ...appStatus,
      descriptionEditable : !appStatus.descriptionEditable
    });
  }

  useEffect(()=>{
    if(!vid.allowedLanguages.includes("DE")){
      document.getElementById("btnDE").disabled = true;
    }else if(vid.video.titles.find(t=>t.language==="DE").text.length === 0 && vid.video.descriptions.find(t=>t.language==="DE").text.length === 0){
      document.getElementById("btnDE").disabled = true;
    }
    if(!vid.allowedLanguages.includes("EN")){
      document.getElementById("btnEN").disabled = true;
    }else if(vid.video.titles.find(t=>t.language==="EN").text.length === 0 && vid.video.descriptions.find(t=>t.language==="EN").text.length === 0){
      document.getElementById("btnEN").disabled = true;
    }
    if(!vid.allowedLanguages.includes("ES")){
      document.getElementById("btnES").disabled = true;
    }else if(vid.video.titles.find(t=>t.language==="ES").text.length === 0 && vid.video.descriptions.find(t=>t.language==="ES").text.length === 0){
      document.getElementById("btnES").disabled = true;
    }

    // Sind Badwords gefunden worden, so blinkt der Badwords marker
    function toggleBwColors(){
      if(bwColor === "text-red-500"){
        setBwColor("text-yellow-500");
      }else{
        setBwColor("text-red-500");
      }
    }
    if(!badwords){
      return;
    }
    let interv = setInterval(toggleBwColors,500);
    return () => clearInterval(interv);
  });

  return(
    <div className="">
      <button id="btnDE" className={`absolute top-2 left-[10px] ${(appStatus.descriptionLanguage === 'DE') ? "text-zanBlue" : "text-gray-400"}  hover:text-zanBlue disabled:text-white font-bold self-end h-[30px] w-[40px]`} onClick={()=>oc("DE")}>DE</button>
      <button id="btnEN" className={`absolute top-2 left-[50px] ${(appStatus.descriptionLanguage === 'EN') ? "text-zanBlue" : "text-gray-400"} hover:text-zanBlue disabled:text-white font-bold self-end h-[30px] w-[40px]`} onClick={()=>oc("EN")}>EN</button>
      <button id="btnES" className={`absolute top-2 left-[90px] ${(appStatus.descriptionLanguage === 'ES') ? "text-zanBlue" : "text-gray-400"} hover:text-zanBlue disabled:text-white font-bold self-end h-[30px] w-[40px]`} onClick={()=>oc("ES")}>ES</button>
      <button className={`absolute top-2 left-[130px] text-black ${appStatus.descriptionEditable ? "text-zanBlue" : "text-gray-400"} hover:text-zanBlue font-bold self-end h-[30px] w-[40px]`} onClick={toggleEdit}>Edit</button>
      {badwords ? <p className={`absolute left-[200px] top-2 ${bwColor} font-bold`} title="Badwords left in Text">⚠️BADWORDS⚠️</p>: ""}
    </div>
  );
}

// Der Titel der Albumbeschreibung
function VideoDescriptionTitle({appStatus,vid,oc,foundwords}){
  // Textfield for edit mode, cannot be highlighted
  let edit = <input className="absolute left-5 top-9 h-6 w-[34vw] text-sm bg-transparent outline-none dark:bg-gray-500 border-b-2" type="text" value={vid.video.titles.find(t=>t.language===appStatus.descriptionLanguage)?.text??""}
                onChange={(e) => oc(e.target.value)}></input>
  // Highlighted text, cannot be edited without moving markings
  let plain = <Highlighter 
                searchWords={foundwords}
                textToHighlight ={vid.video.titles.find(t=>t.language===appStatus.descriptionLanguage)?.text??""}
                className={"absolute left-5 top-9 h-6 w-[34vw] text-sm bg-transparent outline-none dark:bg-gray-500 border-b-2 overflow-y-scroll"}
              />
  return(
    <div className="dark:bg-gray-500">
      {appStatus.descriptionEditable ? edit : plain}
    </div>
  );
}

// Der große Text der Albumbeschreibung
function VideoDescriptionText({appStatus,vid,oc,foundwords}){  
  // Textfield for edit mode, cannot be highlighted
  let edit = <textarea className="absolute left-5 top-[64px] h-[8vh] w-[34vw] text-sm bg-transparent outline-none dark:bg-gray-500 resize-none" value={vid.video.descriptions.find(t=>t.language===appStatus.descriptionLanguage)?.text??""}
              onChange={(e) => oc(e.target.value)}></textarea>;
  // Highlighted text, cannot be edited without moving markings
  let plain = <Highlighter 
                searchWords={foundwords}
                textToHighlight ={vid.video.descriptions.find(t=>t.language===appStatus.descriptionLanguage)?.text??""}
                className={"absolute left-5 top-[64px] h-[8vh] w-[34vw] text-sm bg-transparent outline-none dark:bg-gray-500 resize-none overflow-y-scroll"}
              />
  return(
    <div className="">
      {appStatus.descriptionEditable ? edit : plain}
    </div>
  );
}

// Überprüft das Video auf unzulässige Werte vor dem Raten
function videoValidator(appStatus,vid,setAppStatus,checkPreviewImages){
  // preselected fsk16 preview is not rated 18 test
  if(checkPreviewImages){
    if(appStatus.previewPictureStats.findIndex(p=>p.previewPic===16)!==-1 && appStatus.previewPictureStats.find(p=>p.previewPic===16).rating === 18){
      let pps = appStatus.previewPictureStats;
      pps.find(p=>p.previewPic===16).previewPic = 0;
      setAppStatus({
        ...appStatus,
        previewPictureStats : pps
      });
      //alert("FSK 16 Bildvorschau ist mit FSK18 gerated");
      //return false;
    }
  }
  if(appStatus.audioQuality === -1){
    alert("Der Ton muss ausgewählt werden");
    return false;
  }
  if(["53_1","53_2","53_9"].includes(vid.video.type)){
    return true;
  }
  let t18s = appStatus.trailerFSK18Start;
  let t18e = appStatus.trailerFSK18End;
  let t16s = appStatus.trailerFSK16Start;
  let t16e = appStatus.trailerFSK16End;
  let mDur = new Date(appStatus.maxDuration*1000).toLocaleTimeString('de',{ timeZone: 'UTC' })
  if(!("00:00:00" <= t18s && t18s <= mDur)){
    alert("Trailer 18 Start ist außerhalb der gültigen Dauer");
    return false;
  }
  if(!("00:00:00" <= t18e && t18e <= mDur)){
    alert("Trailer 18 Ende ist außerhalb der gültigen Dauer");
    return false;
  }
  if(!("00:00:00" <= t16s && t16s <= mDur) && !appStatus.trailerFSK16Disabled){
    alert("Trailer 16 Start ist außerhalb der gültigen Dauer");
    return false;
  }
  if(!("00:00:00" <= t16e && t16e <= mDur) && !appStatus.trailerFSK16Disabled){
    alert("Trailer 16 Ende ist außerhalb der gültigen Dauer");
    return false;
  }
  if(t18s > t18e){
    alert("Trailer 18 Start-Ende vertauscht");
    return false;
  }
  if(t16s > t16e){
    alert("Trailer 16 Start-Ende vertauscht");
    return false;
  }
  return true;
}