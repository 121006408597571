import React, {useEffect, useState} from "react";
import NavBar from "./NavBar";
import { getNextAlbum, capitalizeFirstLetter, getFirstCat, savePhotoalbum, hidePhotoalbum, rejectPhotoalbum, deletePhotoalbum, getCookie } from "../scripts/xhrf";
import Highlighter from "react-highlight-words";
import { Tag, RejectOverlay, NewUserInfo, checkType } from "./PageSnippets";

// TODO Album365 ?

export default function PhotoRateApp(){

  // RatingCat ist für die Auswahl der Unterkategorien wichtig. Rating und Rerating haben jeweils eine eigene Nummer
  const ratingCat = (sessionStorage.getItem('mode')===null ? 1 : (sessionStorage.getItem('mode')==="check"? 1 : 2));   // Main Category number

  // In album werden alle Infos aus der Query gespeichert
  const [album,setAlbum] = useState({});

  // In der secondaryCategory wird die Unterkategorie gepseichert, die beim Wechsel dieser einen Reload triggert
  const [secondaryCategory,setSecondaryCategory] = useState(getFirstCat(ratingCat));

  // Status zum manuellen refresh
  const [doRefresh,setDoRefresh] = useState(false);

  // Informationen über die Webapplikation werden hier gespeichert
  const [appStatus,setAppStatus] = useState({
    sort : false,
    slot : -1,
    premiumOnly : false,
    albumId : "",
    userId : ""
  });
  // Photocat/secondaryCategory: 0:Feed, 1:Profile, 2:Shop, 3:Free, 4:Dating, 5:Pool, 6:Campaigns
  const [loading,setLoading] = useState(true);  // state to show loading circle

  // Fetches Data on load
  useEffect(()=>{
    // Fetching Data and initializing appStatus
    async function getData(){
      let searchAlbum = 0;
      let searchUser = 0;
      if(window.location.pathname !=="/photoRating"){
        searchAlbum = window.location.pathname.split("/")[2];
      }else if(appStatus.albumId !== undefined && appStatus.albumId !== ""){
        searchAlbum = appStatus.albumId;
      }else if(appStatus.userId !== undefined && appStatus.userId !== ""){
        searchUser = appStatus.userId;
      }
      let a = null;
      try{
        a = await getNextAlbum(searchAlbum,searchUser,(sessionStorage.getItem('sc') === "-1" ? 'SHOP' : sessionStorage.getItem('sc') ?? 'SHOP'),sessionStorage.getItem('mode') ?? "check",appStatus.sort,appStatus.slot,appStatus.premiumOnly);
      }catch(err){
        alert("Server Error :" + err);
        return;
      }
      console.log(a);
      if(a.errors !== undefined){
        alert("Fehler: " + a.errors[0].message);
      }

      // If there is nothing to Rate then show the "nothing to rate" page
      if(a.data.photoalbumRating === null){
        setSecondaryCategory(-1);
        return;
      }else if(searchAlbum !== 0 || searchUser !== 0){
        // Falls nach einem Album gesucht wurde, wird hier die entsprechende Unterkategorie zur Anzeige in den sessionstorage geschrieben
        sessionStorage.setItem('sc',a.data.photoalbumRating.photoalbum.ratingGroup);
      }

      // Makes the Badwords first Letter Case insensitive
      let sw = [];
      for (let e in a.data.photoalbumRating.photoalbum.suspectedWords){
        sw.push(a.data.photoalbumRating.photoalbum.suspectedWords[e]);
        sw.push(capitalizeFirstLetter(a.data.photoalbumRating.photoalbum.suspectedWords[e]));
      }
      a.data.photoalbumRating.photoalbum.suspectedWords = sw;

      // In Album wird die Query gespeichert
      setAlbum(a.data.photoalbumRating);
      /* Eine Erklärung zu den Werten in AppStatus:
      selectedRating: Welcher FSK Button ist ausgewählt
      selectedRejection: Welcher Ablehngrund ist ausgewählt (Ablehngründe haben hier intern ein "FSK-Rating" von unter 12)
      selectedFlags: Eine Liste von ausgewählten Bilderflags
      selectedPics: Eine Liste der ausgewählten Bilder im Multiview
      nowRatedPics: Eine Liste welche Bilder im aktuellen Rating geratet wurden
      alreadyRatedPics: Eine Liste welche Bilder bereits gerated wurden
      currentPic: Welches Bild in der Einzelbildauswahl das aktuelle ist
      viewMode: Singleview, Multiview etc. Die 4 über der Bildanzeige
      keepFlag: Ob Flags beim rating unangetastet bleiben sollen
      keepFsk: Ob FskRatings beim rating unangetastet bleiben sollen
      limitFsk: Für Sedcard/Profilbilder dürfen je nach Kategorie Flirt/Normal/XXX keine härteren Ratings vergeben werden
      limitFskEnabled: Ob das Limit aktiv ist
      descriptionLanguage: Aktuell angezeigte Sprache in der Beschreibung
      rejectOverlay: Ob das Overlay für Reject Album angezeigt wird
      deleteOverlay: Ob das Overlay für Delete Album angezeigt wird
      sort: Sortierung der Alben beim Query (Neuste oder Älteste zuerst)
      slot: Slotposition falls mehrere Mitarbeiter Bilder gleichzeitig raten wollen
      premiumOnly: Ob beim Query nur Premiuminhalte angezeigt werden sollen
      */
      setAppStatus({
        selectedRating : checkType(a.data.photoalbumRating.photoalbum.type,a.data.photoalbumRating.photoalbum.pictures[a.data.photoalbumRating.photoalbum.pictures.findIndex(i => (ratingCat===0? !i.isChecked : !i.isRechecked))]?.fsk),
        selectedRejection : "",
        selectedFlags : (a.data.photoalbumRating.account.platforms.length===1 && a.data.photoalbumRating.account.platforms.includes("VXPAGES")?["vxpages"]:[]).concat(a.data.photoalbumRating.photoalbum.pictures[a.data.photoalbumRating.photoalbum.pictures.findIndex(i => (ratingCat===0? !i.isChecked : !i.isRechecked))]?.flags), // Visit-x, NS, 3rd Party, Fetisch, VXP
        selectedPics : [],
        nowRatedPics : a.data.photoalbumRating.photoalbum.pictures.map(()=> false),
        alreadyRatedPics : a.data.photoalbumRating.photoalbum.pictures.map(i=> (ratingCat===1? i.isChecked : i.isRechecked)),
        currentPic : a.data.photoalbumRating.photoalbum.pictures.findIndex(i => (ratingCat===1? !i.isChecked : !i.isRechecked)),
        viewMode : 0,
        keepFlag : false,
        keepFsk : false,
        limitFsk: (a.data.photoalbumRating.photoalbum.type === "51_3" ? 12 : (a.data.photoalbumRating.photoalbum.type === "51_1" ? 16 : 18)), // 51_3 Flirt, 51_1 "Normal", 51_2 XXX
        limitFskEnabled : sessionStorage.getItem('sc')==="SEDCARD",
        descriptionLanguage : "DE",
        descriptionEditable : false,
        rejectOverlay : false,
        deleteOverlay : false,
        sort : appStatus.sort,
        slot : appStatus.slot,
        premiumOnly : appStatus.premiumOnly,
        albumId : appStatus.albumId,
        userId : appStatus.userId
      });
      setLoading(false);
      setDoRefresh(false);
    }
    setLoading(true);
    getData();
  },[doRefresh,secondaryCategory,ratingCat,appStatus.sort,appStatus.slot,appStatus.premiumOnly,appStatus.albumId,appStatus.userId]);
  
  /************************************************/
  /*                   RATING                     */
  /************************************************/
  // Rating =  0-10: Rejectreasons; 12; 16; 18
  // Diese Funktion wird aufgerufen bei Rate/Reject Picture
  // Je nach Viewmode wird nur ein Bild oder mehrere gerated
  function ratePics(){
    // vvv Singlerating vvv
    if(appStatus.viewMode === 0 && (album.photoalbum.pictures.length !== appStatus.currentPic)){
      let p = album.photoalbum.pictures;
      let nr = appStatus.nowRatedPics;
      if(!appStatus.keepFsk){     // If keepFSK then FSK rating is ignored
        if(!(appStatus.limitFskEnabled && appStatus.selectedRating > appStatus.limitFsk)){
          p[appStatus.currentPic].fsk = appStatus.selectedRating;
        }
        if(appStatus.selectedRating<12){  // Falls das Bild abgelehnt wird
          p[appStatus.currentPic].rejectionId = appStatus.selectedRejection;
        }
      }
      if(ratingCat===1){
        p[appStatus.currentPic].isChecked = true;   // Beim ersten Check
      }else{
        p[appStatus.currentPic].isRechecked = true;   // Beim recheck
      }
      nr[appStatus.currentPic] = true;
      if(!appStatus.keepFlag){     // If keepFlag then Flag rating is ignored
        p[appStatus.currentPic].flags = appStatus.selectedFlags;
      }
      setAlbum({    // Album wird aktualisiert
        ...album,
        photoalbum : {
          ...album.photoalbum,
          pictures: p
        }
      });
      let nextPic = -1;
      let nextFsk = 20;
      let nextFlags = [];
      for(let e in album.photoalbum.pictures){      // Nächstes Bild und FSK vorauswahl wird ausgewählt
        if(!(appStatus.alreadyRatedPics[e] || nr[e])){
          nextPic = Number(e);
          nextFsk = checkType(album.photoalbum.type,album.photoalbum.pictures[e].fsk);
          nextFlags = album.photoalbum.pictures[e].flags;
        }
      }
      if(nextPic !== -1){ // Falls ein weiteres Bild folgt, werden die Vorauswahl angewahndt
        setAppStatus({
          ...appStatus,
          selectedRating : nextFsk,
          selectedFlags : nextFlags,
          currentPic : nextPic,
          nowRatedPics : nr
        });
      }else{ // Falls kein Bild folgt zur fertigen Ansicht wechseln
        setAppStatus({
          ...appStatus,
          viewMode : 2,
          selectedFlags : nextFlags,
          currentPic : nextPic,
          nowRatedPics : nr,
          keepFsk : false,
          keepFlag : false
        });
      }
      // vvv Multirating vvv
    }else{
      let p = album.photoalbum.pictures;
      let nr = appStatus.nowRatedPics;
      let ar = appStatus.alreadyRatedPics;
      for(let e in appStatus.selectedPics){
        if(appStatus.selectedPics[e]){
          if(!appStatus.keepFsk){     // If keepFSK then FSK rating is ignored
            if(!(appStatus.limitFskEnabled && appStatus.selectedRating > appStatus.limitFsk)){ // Falls das Limit enthalten ist soll nicht darüber gerated werden
              p[e].fsk = appStatus.selectedRating;
            }
            if(appStatus.selectedRating<12){      // Falls das Bild abgelehnt wird
              p[e].rejectionId = appStatus.selectedRejection;
            }
          }
          if(ratingCat===1){        // Normaler check
            p[e].isChecked = true;
          }else{                    // Recheck
            p[e].isRechecked = true;
          }
          nr[e] = true;
          if(ar[e]===true){
            ar[e] = false;
          }
          if(!appStatus.keepFlag){     // If keepFlag then Flag rating is ignored
            p[e].flags = appStatus.selectedFlags;
          }
          console.log(appStatus.selectedRating);
          console.log(p[e]);
        }
      }
      setAlbum({      // Album wird aktualisiert
        ...album,
        photoalbum : {
          ...album.photoalbum,
          pictures : p
        }
      });
      let nextPic = -1;   // Nächstes ungeratetes Bild wird ausgewählt
      for(let e in album.photoalbum.pictures){
        if(!(ar[e] || nr[e])){
          nextPic = Number(e);
        }
      }
      let nextMode = 1;
      if(nextPic === -1){   // Falls alles gerated wurde wechsel zur Endansicht
        nextMode = 2;
      }
      setAppStatus({
        ...appStatus,
        selectedPics : album.photoalbum.pictures.map(()=>(false)),
        selectedFlags : [],
        keepFsk : false,
        keepFlag : false,
        currentPic : nextPic,
        nowRatedPics : nr,
        viewMode : nextMode
      });
    }
  }

  // Sendet die Mutation an den Server und lädt danach das nächste Album
  async function saveAndReleaseAlbum(){
    setLoading(true);
    await savePhotoalbum(album,ratingCat,album.photoalbum.pictures);
    sessionStorage.setItem("previous",album.photoalbum.albumId);
    clearSearch();
    setDoRefresh(true);
  }

  // Sendet die Mutation an den Server und lädt danach das nächste Album
  async function saveAndSkipAlbum(){
    let rated = album.photoalbum.pictures.filter((x,index) => (appStatus.nowRatedPics[index] === true));
    console.log(rated);
    await savePhotoalbum(album,ratingCat,rated);
    sessionStorage.setItem("previous",album.photoalbum.albumId);
    let ss = sessionStorage.getItem('skipIds')??"[]";
    ss = JSON.parse(ss);
    if(!ss.includes(album.photoalbum.albumId)){
      ss.push(album.photoalbum.albumId);
    }
    clearSearch();
    sessionStorage.setItem('skipIds',JSON.stringify(ss));
    setDoRefresh(true);
  }

  // Überspringt das aktuelle Album indem das aktuelle Album zu dem skippedIDs im sessionstorage hinzugefügt wird.
  // Der Wert wird gelöscht beim wechseln der Haupt- oder Unterkategorie
  function skipAlbum(){
    let ss = sessionStorage.getItem('skipIds')??"[]";
    ss = JSON.parse(ss);
    if(!ss.includes(album.photoalbum.albumId)){
      ss.push(album.photoalbum.albumId);
    }
    sessionStorage.setItem('skipIds',JSON.stringify(ss));
    sessionStorage.setItem("previous",album.photoalbum.albumId);
    clearSearch();
    setDoRefresh(true);
  }

  // Springt zum vorherigen Album. Bei einem Rate oder Skip wird die AlbumID vom letzten Album im sessionstorage gespeichert.
  // Mit dem previous Button wird eine Suche nach dieser AlbumID ausgeführt  
  function previousAlbum(){
    if(sessionStorage.getItem("previous") !== null ){
      window.location.pathname = "/photoRating/" + sessionStorage.getItem("previous");
      sessionStorage.removeItem("previous");
    }
  }

  // Versteckt das Album (was auch immer das bedeutet)
  function hideAlbum(){
    hidePhotoalbum(album);
    setDoRefresh(true);
  }

  // Lehnt das Album ab
  async function rejectAlbum(descDE,descEN){
    setLoading(true);
    await rejectPhotoalbum(album,descDE,descEN);
    setDoRefresh(true);
  }

  // Löscht das Album
  function deleteAlbum(comment){
    deletePhotoalbum(album,comment);
    setDoRefresh(true);
  }

  function clearSearch(){
    setAppStatus({
      ...appStatus,
      albumId : "",
      userId : ""
    });
    if(window.location.pathname !== "/photoRating"){
      window.location.pathname = "/photoRating";
    }
  }

  // Applies and removes the keydownEvent for hotkeys
  useEffect(()=>{
    function setRating(e){
      let ae = document.activeElement.nodeName;
      if(!(ae === 'INPUT' || ae === 'TEXTAREA')){ // Disables hotkeys while writing
        if(e.repeat){       // Only activates once per press
          return;
        }
        let r = appStatus.selectedRating;
        switch(e.key){          // 1,2,3 setzen FSK Rating. Space rated das aktuelle Bild
          case "1": r = 18;
                  break;
          case "2": r = 16;
                  break;
          case "3": r = 12;
                  break;
          case " ": e.preventDefault(); // Space otherwise activates the last used button
                  ratePics();
                  break;
          default: break;
        }
        if(appStatus.limitFskEnabled && r > appStatus.limitFsk){
          return;
        }
        if(r !== appStatus.selectedRating){
          setAppStatus({
            ...appStatus,
            selectedRating :r
          });
        }
      }
    }    
    window.addEventListener("keydown",setRating);
    return () => window.removeEventListener("keydown",setRating);
    // Important to remove on unloading window. Otherwise multiple listeners
  });

  /************************************************/
  /*                   SETTINGS                   */
  /************************************************/
 

  let tagsAndDescription = null;    // Shows shop and description only on Feed Shop and Free albums
  if(album.isTagsVisible || album.isTextVisible){
    tagsAndDescription = <AlbumRating album={album} setAlbum={setAlbum} appStatus={appStatus} setAppStatus={setAppStatus}/>
  }

  let contentF = <>
                  <ContentField
                    tagsAndDescription={tagsAndDescription}
                    saveAndReleaseAlbum={saveAndReleaseAlbum}
                    saveAndSkipAlbum={saveAndSkipAlbum}
                    album={album}
                    setAlbum={setAlbum}
                    appStatus={appStatus}
                    setAppStatus={setAppStatus}
                    ratePics={ratePics}
                    skipAlbum={skipAlbum}
                    previousAlbum={previousAlbum}
                    hideAlbum={hideAlbum}
                  />
                  {(appStatus.rejectOverlay?<RejectOverlay reasons={album.allowedAlbumRejectionReasons} appStatus={appStatus} setAppStatus={setAppStatus} oc={rejectAlbum}/>:null)}
                  {(appStatus.deleteOverlay?<DeleteOverlay appStatus={appStatus} setAppStatus={setAppStatus} oc={deleteAlbum}/>:null)}
                </>

if(loading){      // When loading there is only Navbar and Loading Circle
  contentF = <div className="w-full h-[92vh] bg-zanBg dark:bg-gray-700">
              <img className="h-[5vw] w-[5vw] left-[47.5vw] top-[40vh] absolute blur-sm dark:invert" alt="" src="/icons/ajax-loader-big.gif"></img>
             </div>
}

  return(
    <div className="dark:text-white transition-all min-w-[1750px]">
      <NavBar ratingCat={ratingCat}
        setSecondaryCategory={setSecondaryCategory}
      />

      {secondaryCategory !== -1 ? contentF : <p className="absolute top-[10vh] left-2">{"No photos to rate"}</p>}   {/* Falls keine Bilder vorhanden sind*/}
    </div>
  );
}

// Overlay für das Löschen von Alben
function DeleteOverlay({appStatus,setAppStatus,oc}){
  const [delComment,setDelComment] = useState("")
  function closeOverlay(){
    setAppStatus({
      ...appStatus,
      deleteOverlay : false
    });
  }
  return(
    <div className="w-[400px] h-[200px] bg-zanRed border-2 border-black absolute left-[40vw] top-[40vh]">
      <p className="text-2xl text-white font-bold">Deletion Comment</p>
      <button className="absolute top-0 right-0 bg-red-600 hover:bg-red-700 text-white text-xl p-1" onClick={closeOverlay}>Close</button>
      <input type={"text"} className="w-[395px] absolute top-14" value={delComment} onChange={(e)=>setDelComment(e.target.value)}></input>
      <button  className="absolute bottom-0 w-full bg-red-600 hover:bg-red-700 text-white p-1" onClick={()=>oc(delComment)}>DELETE ALBUM</button>
    </div>
  );
}

// Die Komponente für das Haupt-content-Feld
function ContentField({tagsAndDescription,saveAndReleaseAlbum,saveAndSkipAlbum,album,setAlbum,appStatus,setAppStatus,ratePics,skipAlbum,previousAlbum,hideAlbum}){
  return(
    <div className="absolute top-[11vh] w-full">
      <div className="relative">
        <SearchAndFilter album={album} appStatus={appStatus} setAppStatus={setAppStatus}/>
        <NewUserInfo 
          pos={"absolute left-[41.5vw] top-[0] h-[15vh] w-[57.5vw]"}
          maxTextHeight={"h-[6.2vh]"}
          acc={album.account}
          showOrigin={false}
        />
        <BildSektion
          album={album}
          appStatus={appStatus}
          setAppStatus={setAppStatus}
        />
        <FSKList appStatus={appStatus} setAppStatus={setAppStatus}/>
        <FlagList album={album} appStatus={appStatus} setAppStatus={setAppStatus}/>
        <KeepStuff appStatus={appStatus} setAppStatus={setAppStatus}/>
        <RejectReasons album={album} appStatus={appStatus} setAppStatus={setAppStatus}/>
        <PictureTags
          album={album}
          setAlbum={setAlbum}
          appStatus={appStatus}
        />
        <RateAndReleaseSection 
          album={album}
          appStatus={appStatus}
          ratePics={ratePics}
          saveAndReleaseAlbum={saveAndReleaseAlbum}
        />
        {tagsAndDescription}
        <AdditionalInformations album={album} setAlbum={setAlbum} appStatus={appStatus} setAppStatus={setAppStatus}/>
        <RejectAndReleaseButtons album={album} appStatus={appStatus} setAppStatus={setAppStatus} saveAndSkipAlbum={saveAndSkipAlbum} skipAlbum={skipAlbum} previousAlbum={previousAlbum} hideAlbum={hideAlbum}/>
      </div>
    </div>
  );
}

// Die Komponente in der die Informationen über der Bildanzeige angezeigt werden. AlbumID, ID-Shot, Preis,...
function AdditionalInformations({album,setAlbum,appStatus,setAppStatus}){
  let np = album.photoalbum.pictures.filter(i => !i.checked).length;
  let ch = album.photoalbum.picturesCheckedTotal;
  let rj = album.photoalbum.picturesRejectedTotal;

  // Changeprice
  function cp(val){
    setAlbum({
      ...album,
      photoalbum : {
        ...album.photoalbum,
        price : val
      }
    });
  }

  // Alle vorhandenen Preise mit abstufungen. Wird mithilfe von maxPrice gecappt und als Auswahl für den Preis gegeben
  let mp = album.photoalbum.priceRange;
  mp = mp.map((item,index) => <option key={index} value={item}>{item+" €"}</option>); 

  let priceList = null;
  if(album.isPriceVisible){
    priceList = <>
                  <p className="absolute top-[1vh] left-[20vw] text-xs text-disabledGreyText">Price: </p>
                  <select className="absolute top-[3.5vh] left-[20vw] px-2 py-1 bg-disabledGrey rounded-full" value={album.photoalbum.price} onChange={(e)=>cp(e.target.value)}>
                    {mp}
                  </select>
                  <p className="absolute top-[7vh] left-[20vw] text-xs text-disabledGreyText"> Max price: {album.photoalbum.maxPrice} EUR</p>
                </>
  }

  function changeAktion(){
    setAlbum({
      ...album,
      aktion365 : !album.aktion365
    });
  }
  function changePremium(e){
    setAppStatus({
      ...appStatus,
      premiumOnly : !appStatus.premiumOnly
    })
  }

  const isInt = getCookie("internal")==="X" || true;    // Nur Interne Mitarbeiter bekommen einen ID-Shot angezeigt

  return(
    <div className="absolute top-0 left-[1vw] w-[39.5vw] h-[11vh] bg-white dark:bg-slate-700 rounded-3xl">
        <p className="absolute top-[1vh] left-[6vw] text-lg font-bold">{album.photoalbum.typeName}</p>
        <p className="absolute top-[4vh] left-[6vw] text-[13px] font-bold"><span className="text-disabledGreyText font-normal">Album-ID:</span>{" " + album.photoalbum.albumId}</p>
        <p className="absolute top-[6vh] left-[6vw] text-[13px] font-bold"><span className="text-disabledGreyText font-normal">Pictures:</span>{" " + album.photoalbum.picturesTotal} (New:{np}, checked:{ch}, rejected:{rj})</p>
        {priceList}
        <img className="absolute top-[3vh] left-[28vw]" alt="" src={album.premiumOnly ? "/icons/check-circle.png" : "/icons/icon-base.png"} onClick={()=>changePremium()}></img>
        <p className="absolute top-[3.25vh] left-[29.5vw] text-sm">Premium Only</p>
        {album.isAlbumFlagsVisible && 
        <>
          <img className="absolute top-[6vh] left-[28vw]" alt="" src={album.aktion365 ? "/icons/check-circle.png" : "/icons/icon-base.png"} onClick={()=>changeAktion()}></img>
          <p className="absolute top-[6.25vh] left-[29.5vw] text-sm">A 365</p>
        </>}
        {(isInt && <img alt="IdShot" className="absolute left-[1vw] top-[1.5vh] h-[8vh] max-w-[4vw] hover:max-w-[10vw] rounded-md hover:w-64 hover:h-auto" src={album.account.documents[0]?.urls}></img>)}
    </div>
  );
}

// Die Komponente die die Bescheibung des Albums beinhaltet in weiteren Unterkomponenten
function AlbumDescription({album,setAlbum,appStatus,setAppStatus}){
  const [foundwords,setFoundwords] = useState([]);
  // Changing the displayed language
  function changeLanguage(val){
    setAppStatus({
      ...appStatus,
      descriptionLanguage : val
    });
  }
  // Eventhandler for Title editing
  function onChangeTi(val){
    if(album.photoalbum.titles.find(i=>i.language===appStatus.descriptionLanguage)!==undefined){
      let d = album.photoalbum.titles;
      d.find(i=>i.language===appStatus.descriptionLanguage).text = val;
      setAlbum({
        ...album,
        photoalbum: {
          ...album.photoalbum,
          titles : d
        }
      });
    }
  }
  // Eventhandler for Text editing
  function onChangeTe(val){
    if(album.photoalbum.descriptions.find(i=>i.language===appStatus.descriptionLanguage)!==undefined){
      let d = album.photoalbum.descriptions;
      d.find(i=>i.language===appStatus.descriptionLanguage).text = val;
      setAlbum({
        ...album,
        photoalbum: {
          ...album.photoalbum,
          descriptions : d
        }
      });
    }
  }

  // Searches for Badwords
  let apti = JSON.stringify(album.photoalbum.titles);
  let apte = JSON.stringify(album.photoalbum.descriptions);
  useEffect(()=>{
    function applyFilters(){
      let w = [];
      let l = album.photoalbum.suspectedWords;
      if(album.allowedLanguages.includes("DE")){
        for(let e in l){
          if(album.photoalbum.titles.find(t=>t.language==="DE").text.includes(l[e])){
            w.push(l[e]);
          }
        }
        for(let e in l){
          if(album.photoalbum.descriptions.find(t=>t.language==="DE").text.includes(l[e])){
            w.push(l[e]);
          }
        }
      }
      if(album.allowedLanguages.includes("EN")){
        for(let e in l){
          if(album.photoalbum.titles.find(t=>t.language==="EN").text.includes(l[e])){
            w.push(l[e]);
          }
        }
        for(let e in l){
          if(album.photoalbum.descriptions.find(t=>t.language==="EN").text.includes(l[e])){
            w.push(l[e]);
          }
        }
      }
      if(album.allowedLanguages.includes("ES")){
        for(let e in l){
          if(album.photoalbum.titles.find(t=>t.language==="ES").text.includes(l[e])){
            w.push(l[e]);
          }
        }
        for(let e in l){
          if(album.photoalbum.descriptions.find(t=>t.language==="ES").text.includes(l[e])){
            w.push(l[e]);
          }
        }
      }
      for(let e in l){
        if(album.photoalbum.allTags.includes(l[e])){
          w.push(l[e]);
        }
      }
      setFoundwords(w);
    }
    applyFilters();
  },[album.photoalbum.suspectedWords,album.allowedLanguages,apti,apte,album.photoalbum.titles,album.photoalbum.descriptions,album.photoalbum.allTags,album.photoalbum.allTags.length]);
  
  return(
    <div className="absolute left-[41.5vw] top-[61.5vh] w-[57.5vw] h-[17vh] bg-white dark:bg-slate-700 rounded-3xl">
      <div className="h-full grid grid-rows-6 justify-items-center relative">
        <AlbumDescriptionLanguageButtons album={album} oc={changeLanguage} appStatus={appStatus} setAppStatus={setAppStatus} badwords={(foundwords.length>0?true:false)}/>
        <AlbumDescriptionTitle appStatus={appStatus} album={album} oc={onChangeTi} foundwords={foundwords}/>
        <AlbumDescriptionText appStatus={appStatus} album={album} oc={onChangeTe} foundwords={foundwords}/>
      </div>
    </div>
  );
}

// Buttons zur Sprachauswahl
function AlbumDescriptionLanguageButtons({album,oc,appStatus,setAppStatus,badwords}){
  const [bwColor,setBwColor] = useState("text-zanRbg-zanRed");

  // Switches between Badwords marking and edit mode
  function toggleEdit(){
    setAppStatus({
      ...appStatus,
      descriptionEditable : !appStatus.descriptionEditable
    });
  }

  useEffect(()=>{
    if(!album.allowedLanguages.includes("DE")){
      document.getElementById("btnDE").disabled = true;
    }else if(album.photoalbum.titles.find(t=>t.language==="DE").text.length === 0 && album.photoalbum.descriptions.find(t=>t.language==="DE").text.length === 0){
      document.getElementById("btnDE").disabled = true;
    }
    if(!album.allowedLanguages.includes("EN")){
      document.getElementById("btnEN").disabled = true;
    }else if(album.photoalbum.titles.find(t=>t.language==="EN").text.length === 0 && album.photoalbum.descriptions.find(t=>t.language==="EN").text.length === 0){
      document.getElementById("btnEN").disabled = true;
    }
    if(!album.allowedLanguages.includes("ES")){
      document.getElementById("btnES").disabled = true;
    }else if(album.photoalbum.titles.find(t=>t.language==="ES").text.length === 0 && album.photoalbum.descriptions.find(t=>t.language==="ES").text.length === 0){
      document.getElementById("btnES").disabled = true;
    }

    // Sind Badwords gefunden worden, so blinkt der Badwords marker
    function toggleBwColors(){
      if(bwColor === "text-zanRbg-zanRed"){
        setBwColor("text-yellow-500");
      }else{
        setBwColor("text-zanRbg-zanRed");
      }
    }
    if(!badwords){
      return;
    }
    let interv = setInterval(toggleBwColors,500);
    return () => clearInterval(interv);
  });

  return(
    <div className="">
      <button id="btnDE" className={`absolute top-2 left-[10px] ${(appStatus.descriptionLanguage === 'DE') ? "text-zanBlue" : "text-gray-400"}  hover:text-zanBlue disabled:text-white font-bold self-end h-[30px] w-[40px]`} onClick={()=>oc("DE")}>DE</button>
      <button id="btnEN" className={`absolute top-2 left-[50px] ${(appStatus.descriptionLanguage === 'EN') ? "text-zanBlue" : "text-gray-400"} hover:text-zanBlue disabled:text-white font-bold self-end h-[30px] w-[40px]`} onClick={()=>oc("EN")}>EN</button>
      <button id="btnES" className={`absolute top-2 left-[90px] ${(appStatus.descriptionLanguage === 'ES') ? "text-zanBlue" : "text-gray-400"} hover:text-zanBlue disabled:text-white font-bold self-end h-[30px] w-[40px]`} onClick={()=>oc("ES")}>ES</button>
      <button className={`absolute top-2 left-[130px] text-black ${appStatus.descriptionEditable ? "text-zanBlue" : "text-gray-400"} hover:text-zanBlue font-bold self-end h-[30px] w-[40px]`} onClick={toggleEdit}>Edit</button>
      {badwords ? <p className={`absolute left-[200px] top-2 ${bwColor} font-bold`} title="Badwords left in Text">⚠️BADWORDS⚠️</p>: ""}
    </div>
  );
}

// Der Titel der Albumbeschreibung
function AlbumDescriptionTitle({appStatus,album,oc,foundwords}){
  // Textfield for edit mode, cannot be highlighted
  let edit = <input className="absolute left-5 top-[4.2vh] h-6 w-[52vw] text-sm bg-transparent outline-none dark:bg-gray-500 border-b-2" type="text" value={album.photoalbum.titles.find(t=>t.language===appStatus.descriptionLanguage)?.text??""}
                onChange={(e) => oc(e.target.value)}></input>
  // Highlighted text, cannot be edited without moving markings
  let plain = <>
                <Highlighter 
                  searchWords={foundwords}
                  textToHighlight ={album.photoalbum.titles.find(t=>t.language===appStatus.descriptionLanguage)?.text??""}
                  className={"absolute left-5 top-[4.2vh] h-6 w-[52vw] text-sm bg-transparent outline-none border-b-2 overflow-y-scroll"}
                />
              </>
  return(
    <div className="dark:bg-gray-500">
      {appStatus.descriptionEditable ? edit : plain}
    </div>
  );
}

// Der große Text der Albumbeschreibung
function AlbumDescriptionText({appStatus,album,oc,foundwords}){
  // Textfield for edit mode, cannot be highlighted
  let edit = <textarea id="descText" className="absolute left-5 top-[7vh] h-[10vh] w-[52vw] text-sm bg-transparent outline-none dark:bg-gray-500 resize-none" value={album.photoalbum.descriptions.find(t=>t.language===appStatus.descriptionLanguage)?.text??""}
              onChange={(e) => oc(e.target.value)}></textarea>;
  // Highlighted text, cannot be edited without moving markings
  let plain = <>
            <Highlighter 
              searchWords={foundwords}
              textToHighlight ={album.photoalbum.descriptions.find(t=>t.language===appStatus.descriptionLanguage)?.text??""}
              className={"absolute left-5 top-[7vh] h-[10vh] w-[52vw] text-sm bg-transparent outline-none dark:bg-gray-500 resize-none overflow-y-scroll"}
            />
          </>;
  return(
    <div className="">
      {appStatus.descriptionEditable ? edit : plain}
    </div>
  );
}

// TODO Überarbeiten sollte alles unnötig sein
function AlbumRating({album,setAlbum,appStatus,setAppStatus}){
  return(
    <div className="row-span-3 col-start-4 col-span-1">
      <div className="h-full grid grid-rows-2">
        <AlbumDescription album={album} setAlbum={setAlbum} appStatus={appStatus} setAppStatus={setAppStatus}/>
        <AlbumTags album={album} setAlbum={setAlbum}/>
      </div>
    </div>
  );
}

// Komponente in der die Albumtags enthalten sind
function AlbumTags({album,setAlbum}){
  const [newTag,setNewTag] = useState("");

  // Adds one or more entered tags seperated by ,
  function addTag(){
    if(newTag.trim() === ''){
      return;
    }
    let t_old = album.photoalbum.allTags;
    let n_old = album.photoalbum.newTags;
    let t_add = newTag.split(',');
    for (let e in t_add){
      if(!t_old.includes(t_add[e].trim()) && !(t_add[e].trim() === "")){
        t_old.push(t_add[e].trim());
        n_old.push(t_add[e].trim());
      }
    }
    setAlbum({
      ...album,
      photoalbum : {
        ...album.photoalbum,
        allTags : t_old,
        newTags : n_old
      }
    });
    setNewTag("");
  }

  // Deletes a single tag
  function removeTag(txt){
    let t = album.photoalbum.allTags;
    let n = album.photoalbum.newTags;
    t.splice(album.photoalbum.allTags.indexOf(txt),1);
    setAlbum({
      ...album,
      photoalbum : {
        ...album.photoalbum,
        allTags : t,
        newTags : n
      }
    });
  }
  // Eventhandler for editing the to-be-added-tag
  function onChangeT(val){
    setNewTag(val);
  }
  // Generates the list of tags
  var tagList;
  if (album.photoalbum.allTags.length > 0){
    tagList = album.photoalbum.allTags.map((t,index) => <Tag key={index} text={t} suspected={album.photoalbum.suspectedWords} newTags={album.photoalbum.newTags} oc={removeTag}/>);
  }else{
    tagList = "";
  }
  // Adds the keydownHandler for Enter
  useEffect(()=>{
    let e = document.getElementById("tagInputField");
    function checkKey(e){
      if(e.repeat){
        return;
      }else if(e.key === "Enter"){
        addTag();
      }
    }
    e.addEventListener("keydown",checkKey);
    return () => {e.removeEventListener("keydown",checkKey)}
  });
  
  return(
    <div className="absolute left-[76vw] top-[42.5vh] w-[23vw] h-[17.5vh] rounded-3xl bg-white">
      <div className="absolute top-0 left-0 h-10 w-full">
        <input id="tagInputField" className="rounded-full bg-disabledGrey text-black m-4 py-1 px-3 text-[15px]" type="text" size="30" value={newTag} placeholder="Insert album tags" onChange={(e) => {onChangeT(e.target.value)}}></input>
        <button className="text-zanBlue my-2 w-7 h-7 inline-block align-middle text-[15px]" onClick={addTag}>
          Apply
        </button>
      </div>
      <div className="absolute top-14 left-4 h-full w-[21vw]">
        <div className="h-[10vh] overflow-y-scroll">
          <ul className="">
            {tagList}
          </ul>
        </div>
      </div>
    </div>
  );
}

// Komponente in der ein Einzelbild oder eine Liste von Bildern angezeigt wird
function Bild({album,appStatus,setAppStatus,setAllSelected}){
  let v = null;
  switch(appStatus.viewMode){
              // vvv Singleview vvv
    case 0: if(appStatus.currentPic === -1){
              break;
            }
            v = <div>
                  <a href={album.photoalbum.pictures[appStatus.currentPic].urlBig} target={"_blank"} rel="noreferrer">
                    <img className="object-contain h-[55vh] border-2 mx-auto border-black dark:border-white max-h-[725px]" src={album.photoalbum.pictures[appStatus.currentPic].urlBig} alt="Einzelbildansicht"/>
                  </a>
                  <p className="text-left mt-10"><span className="text-disabledGreyText">{"ID: "}</span>{album.photoalbum.pictures[appStatus.currentPic].pictureId+" / "+album.photoalbum.pictures[appStatus.currentPic].width+"x"+album.photoalbum.pictures[appStatus.currentPic].height+"px"}</p>
                  <p className="text-left text-black"><span className="text-disabledGreyText">{(album.photoalbum.pictures[appStatus.currentPic].firstRatingName===""?"":"Last rated by: ")}</span>{album.photoalbum.pictures[appStatus.currentPic].firstRatingName+" "+album.photoalbum.pictures[appStatus.currentPic].firstRatingDate.substring(0,10) + " " + album.photoalbum.pictures[appStatus.currentPic].firstRatingDate.substring(11,16)}</p>
                </div>
            break;
              // vvv Multiview vvv
    case 1: v = album.photoalbum.pictures.map((item, index) => ((!appStatus.nowRatedPics[index] && !appStatus.alreadyRatedPics[index]) && <BildSegment url={item.url} urlBig={item.urlBig} key={index} realIndex={index} album={album} appStatus={appStatus} setAppStatus={setAppStatus} setAllSelected={setAllSelected} />));
            break;  // Case 1: Noch nicht geratete Bilder
    case 2: v = album.photoalbum.pictures.map((item, index) => ((appStatus.nowRatedPics[index] && !appStatus.alreadyRatedPics[index]) && <BildSegment url={item.url} urlBig={item.urlBig} key={index} realIndex={index} album={album} appStatus={appStatus} setAppStatus={setAppStatus} setAllSelected={setAllSelected} />));
            break;  // Case 2: Im aktuellen Rating geratete Bilder
    case 3: v = album.photoalbum.pictures.map((item, index) => ((!appStatus.nowRatedPics[index] && appStatus.alreadyRatedPics[index]) && <BildSegment url={item.url} urlBig={item.urlBig} key={index} realIndex={index} album={album} appStatus={appStatus} setAppStatus={setAppStatus} setAllSelected={setAllSelected} />));
            break;  // Case 3: Bereits geratete Bilder. Werden diese erneut gerated landen diese in Case 2
    default :
  }
  return(
    <div className={`absolute top-[7vh] left-[1vw] mx-2 overflow-y-scroll mt-1 h-[66vh] w-[37vw] grid ${appStatus.viewMode === 0?"":"grid-cols-2"}`}>
      {v}
    </div>
  );
}

// Ein Einzelner Bildabschnitt im Multiviewrating
function BildSegment({url,urlBig,realIndex,album,appStatus,setAppStatus,setAllSelected}){
  // This component is for multiview only
  // Selects or deselects the clicked Picture
  function onClick(val){
    let p = appStatus.selectedPics;
    p[val] = !p[val];
    setAppStatus({
      ...appStatus,
      selectedPics : p
    });
  }

  // Selects or deselects all Pictures
  function onDblClick(val){
    let p = appStatus.selectedPics;
    let setTo = !p[val];
    if(appStatus.viewMode === 1){
      p = p.map((pic,index) => (!appStatus.alreadyRatedPics[index] && !appStatus.nowRatedPics[index] ? setTo : false));
    }else if(appStatus.viewMode === 2){
      p = p.map((pic,index) => (!appStatus.alreadyRatedPics[index] && appStatus.nowRatedPics[index] ? setTo : false));
    }else if(appStatus.viewMode === 3){
      p = p.map((pic,index) => (appStatus.alreadyRatedPics[index] && !appStatus.nowRatedPics[index] ? setTo : false ));
    }
    setAppStatus({
      ...appStatus,
      selectedPics : p
    });
    setAllSelected(setTo);
  }

  // Assigns the correct border color to the Picture frames
  let b = "";
  if(album.photoalbum.pictures[realIndex].fsk === 12){
    b = "border-green-600"
  }else if(album.photoalbum.pictures[realIndex].fsk === 16){
    b = "border-blue-600"
  }else if(album.photoalbum.pictures[realIndex].fsk === 18){
    b = "border-red-600"
  }else{
    b = "border-black"
  }
  // Adds tags in shortform as description
  let tgs = [];
  tgs.push(album.photoalbum.pictures[realIndex].flags.includes("vx_branded") ? "/icons/vx260.png" : "");
  tgs.push(album.photoalbum.pictures[realIndex].flags.includes("nskv") ? "/icons/nskv260.png" : "");
  tgs.push(album.photoalbum.pictures[realIndex].flags.includes("third_paty") ? "/icons/3rd-party260.png" : "");
  tgs.push(album.photoalbum.pictures[realIndex].flags.includes("fetish") ? "/icons/fetish260.png" : "");
  tgs.push(album.photoalbum.pictures[realIndex].flags.includes("vxpages") ? "/icons/vxpages260.png" : "");
  let mappedTgs = tgs.map((t,index) => <img alt="" src={t} className={`inline h-5 ${(t !== "" ? "border-[1px] border-black rounded-md" : "")}`} key={index}></img>)
  
  return(
    <div className={`p-2 h-[38vh] ${appStatus.selectedPics[realIndex]? "bg-gray-400 dark:bg-gray-700" : "bg-disabledGrey"}`}>
      <div className="relative">
        <p className="col-span-5 inline">{(album.photoalbum.pictures[realIndex].fsk > 11 ? "Rating: " + album.photoalbum.pictures[realIndex].fsk + " " : "Rejected: " + album.allowedPictureRejectionReasons.find(r=>r.id === album.photoalbum.pictures[realIndex].rejectionId).label)}</p>
        {mappedTgs}
        <a href={urlBig} target="_blank" rel="noreferrer">
          <img src={"/icons/fullscreen.png"} alt="" className="absolute right-2 top-0 dark:invert"></img>
        </a>
        <button onClick={() => onClick(realIndex)} onDoubleClick={() => onDblClick(realIndex)} className={`h-[30vh] place-items-center bg-white grid mx-auto object-fill border-4 ${b}`}>
          <img className="object-contain max-h-[28vh]" src={url} alt="Bildvorschau"/>
        </button>
        <p className="absolute -bottom-8 left-1">{"Tags: " + album.photoalbum.pictures[realIndex].allTags}</p>
      </div>
    </div>
  );
}

/* 'First Unrated'  // Aktuelles Bild
*  'All Unrated'    // Noch zu ratende Bilder
*  'Now Rated'      // Eben geratete Bilder
* 'Rated Picures'   //  Bereits geratete bilder aus dem album
*/
// Leiste zur Auswahl des Viewmodes
function BildLeiste({album,appStatus,setAppStatus,allSelected,setAllSelected}){
  let unRatedCount = 0;
  let nowRatedCount = 0;
  let alreadyRatedCount = 0;
  // Counts the amount of already (un)rated pictures
  for (let e in album.photoalbum.pictures){
    if(appStatus.alreadyRatedPics[e]){
      alreadyRatedCount++;
    }else if(appStatus.nowRatedPics[e]){
      nowRatedCount++;
    }else{
      unRatedCount++;
    }
  }

  // Changes between viewmodes like Singleview or (un)rated Multiviews
  function changeViewMode(mode){
    setAllSelected(false);
    if(mode === 0){
      let t = -1;
      let r = 20; // not rateable value; used for not having any rating selected after the last pic
      for(let e in album.photoalbum.pictures){
        if(!(appStatus.alreadyRatedPics[e] || appStatus.nowRatedPics[e])){
          t = Number(e);
          r = album.photoalbum.pictures[e].fsk;
          break;
        }
      }
      setAppStatus({
        ...appStatus,
        currentPic : t,
        viewMode : mode,
        selectedRating : checkType(album.photoalbum.type,r)
      });
    }else{
      setAppStatus({
        ...appStatus,
        selectedPics : album.photoalbum.pictures.map(() => false),
        selectedRating : 20,
        viewMode : mode
      });
    }
  }

  function changeSelection(){
    if(appStatus.viewMode === 0){
      return;
    }else{
      setAllSelected(!allSelected);
    }
  }

  return(
    <div className="absolute top-[2vh] left-[1vw]">
        <BildLeistenButton vM={appStatus.viewMode} vMNr={0} count={null} oc={changeViewMode}text='First Unrated'/>
        <BildLeistenButton vM={appStatus.viewMode} vMNr={1} count={unRatedCount} oc={changeViewMode}text='All Unrated'/>
        <BildLeistenButton vM={appStatus.viewMode} vMNr={2} count={nowRatedCount} oc={changeViewMode}text='Now Rated'/>
        <BildLeistenButton vM={appStatus.viewMode} vMNr={3} count={alreadyRatedCount} oc={changeViewMode}text='Rated Picures'/>
        <button className={`text-center rounded-full ${allSelected ? "bg-zanBlue text-white" : "bg-white text-zanBlue border-[2px] border-zanBlue"}  h-[30px] px-2`} onClick={() => changeSelection()}>
          (De)select All
        </button>
    </div>
  );
}

// Ein Button in der Bildleiste
function BildLeistenButton({vM,vMNr,count,oc,text}){
  return(
    <button className={`text-center rounded-full ${vM === vMNr ? "bg-zanBlue text-white": "bg-disabledGrey text-black" } h-[30px] px-3 mr-5`} onClick={() => oc(vMNr)}>
      {text + (count!=null? "(" + count + ")" : "")}
    </button>
  );
}

// Die Komponente für die gesamte Bildsektion
function BildSektion({album,appStatus,setAppStatus}){
  const [allSelected,setAllSelected] = useState(false);

  useEffect(()=>{
    let p = appStatus.selectedPics;
    let setTo = allSelected;
    if(appStatus.viewMode === 1){
      p = p.map((pic,index) => (!appStatus.alreadyRatedPics[index] && !appStatus.nowRatedPics[index] ? setTo : false));
    }else if(appStatus.viewMode === 2){
      p = p.map((pic,index) => (!appStatus.alreadyRatedPics[index] && appStatus.nowRatedPics[index] ? setTo : false));
    }else if(appStatus.viewMode === 3){
      p = p.map((pic,index) => (appStatus.alreadyRatedPics[index] && !appStatus.nowRatedPics[index] ? setTo : false ));
    }
    setAppStatus({
      ...appStatus,
      selectedPics : p
    });
    //eslint-disable-next-line
  },[allSelected,setAppStatus]);

  return(
    <div className="absolute left-[1vw] top-[13vh] w-[39.5vw] h-[74vh] bg-white rounded-2xl">
      <BildLeiste album={album} appStatus={appStatus} setAppStatus={setAppStatus} allSelected={allSelected} setAllSelected={setAllSelected}/>
      <Bild album={album} appStatus={appStatus} setAppStatus={setAppStatus} setAllSelected={setAllSelected}/>
    </div>
  );
}

// Die Komponente in der Keep Rating und Keep Flags enthalten ist
function KeepStuff({appStatus,setAppStatus}){
  // (un)sets the keepFSK checkbox
  function onChangeFsk(){
    setAppStatus({
      ...appStatus,
      keepFsk : !appStatus.keepFsk
    });
  }
  // (un)sets the keepFlag checkbox
  function onChangeFlag(){
    setAppStatus({
      ...appStatus,
      keepFlag : !appStatus.keepFlag
    });
  }
  return(
    <div className="absolute left-[77vw] top-[16.5vh] w-[22vw] h-[9vh] bg-white rounded-r-3xl">
      <img className="absolute top-[3vh] left-[3vw]" alt="" src={appStatus.keepFsk ? "/icons/check-circle.png" : "/icons/icon-base.png"} onClick={()=>onChangeFsk()}></img>
      <p className="absolute top-[3.4vh] left-[4.5vw] text-sm">Keep Rating</p>
      <img className="absolute top-[3vh] left-[11vw]" alt="" src={appStatus.keepFlag ? "/icons/check-circle.png" : "/icons/icon-base.png"} onClick={()=>onChangeFlag()}></img>
      <p className="absolute top-[3.4vh] left-[12.5vw] text-sm">Keep Flag</p>
    </div>
  );
}

// Hier befinden sich die Buttons "Rate/Reject Picture(s)" und "Save and Release Album"
function RateAndReleaseSection({album,appStatus,ratePics,saveAndReleaseAlbum}){
  let disableRate = false;
  if(appStatus.selectedRating < 12 || ((appStatus.viewMode !== 0 && !appStatus.selectedPics.includes(true)) || (appStatus.selectedRating === 20 && !appStatus.keepFsk))){
    disableRate = true;     // If rejected selected OR (viewmode multi AND nothing selected) OR (IllegalRatingvalue AND notKeepingFsk)
  }
  let disableReject = false;
  if(appStatus.selectedRating > 11 || (appStatus.viewMode !== 0 && !appStatus.selectedPics.includes(true))){
    disableReject = true; // disabled if no rejectreason selected OR (Multiviewmode && no selected pictures)
  }
  let testJson = JSON.stringify(appStatus.nowRatedPics);
  useEffect(()=>{
    for(let i=0; i<appStatus.alreadyRatedPics.length;i++){
      if(!(appStatus.alreadyRatedPics[i] || appStatus.nowRatedPics[i])){
        document.getElementById("srara").disabled = true;
        return;
      }
    }
    document.getElementById("srara").disabled = false;
  },[appStatus.alreadyRatedPics,appStatus.nowRatedPics,testJson]);
  return(
    <div className="absolute left-[41.5vw] top-[45.5vh] w-[33.5vw] h-[14.5vh] bg-white dark:bg-slate-700 rounded-3xl">
      <button className={`absolute left-[1vw] top-[1.5vh] w-[10vw] h-[11.5vh] rounded-lg font-bold text-xl text-black ${disableRate?"bg-disabledGrey":"bg-lime-500 hover:bg-lime-700"}`}
              onClick={(disableRate ? null : ratePics)} title={"Hotkey : Space"}>
        Rate Picture(s)
      </button>
      <button className={`absolute left-[12vw] top-[1.5vh] w-[10vw] h-[11.5vh] rounded-lg font-bold text-xl ${disableReject ? "bg-disabledGrey text-black" : "bg-zanRed text-white"}`} onClick={(disableReject ? null : ratePics)}>
        Reject Picture(s)
      </button>
      <button id="srara" className="absolute left-[23vw] top-[1.5vh] w-[9.5vw] h-[11.5vh]  rounded-lg font-bold text-xl disabled:bg-disabledGrey disabled:text-black text-white bg-lime-600" onClick={saveAndReleaseAlbum}>
        Save Rating and Release Album
      </button>
    </div>
  );
}

// Einer der FSK Buttons
function FSKButton({fsk,appStatus,setAppStatus}){
  let norm = "";
  let hk = 0;
  // Highlights the selected FSK rating
  switch(fsk){
    case 18: norm = (fsk === appStatus.selectedRating ? "bg-zanRed text-white" : "bg-disabledGrey text-black");
             hk = 1;
    break;
    case 16: norm = (fsk === appStatus.selectedRating ? "bg-zanBlue text-white" : "bg-disabledGrey text-black");
             hk = 2;
    break;
    case 12: norm = (fsk === appStatus.selectedRating ? "bg-green-500 text-white" : "bg-disabledGrey text-black");
             hk = 3;
    break;
    default: console.log("shouldnt happen");
  }
  // Sets the selected FSK rating
  function setFsk(){
    if(appStatus.limitFskEnabled && fsk > appStatus.limitFsk){
      return;
    }
    setAppStatus({
      ...appStatus,
      selectedRating : fsk
    });
  }
  return(
    <button className={`${norm} rounded-2xl font-bold text-[15px] h-[60px] w-[60px]`} onClick={() => setFsk(fsk)} title={"Hotkey : " + hk}>
      {"FSK " + fsk}
    </button>
  );
}

// Liste der Fsk Buttons
function FSKList({appStatus,setAppStatus}){
  return(
    <div className="absolute left-[41.5vw] top-[16.5vh] w-[15.5vw] h-[9vh] bg-white rounded-l-3xl grid grid-cols-3 place-content-center pl-5 pr-10">
      <FSKButton fsk={18} appStatus={appStatus} setAppStatus={setAppStatus}/>
      <FSKButton fsk={16} appStatus={appStatus} setAppStatus={setAppStatus}/>
      <FSKButton fsk={12} appStatus={appStatus} setAppStatus={setAppStatus}/>
    </div>
  );
}

// Ein einzelnes Flag
function Flag({appStatus,setAppStatus,beschreibung, kuerzel, source}){
  // Toggles a single flag
  function toggleFlag(){
    let f = appStatus.selectedFlags;
    if(f.includes(kuerzel)){
      f.splice(f.indexOf(kuerzel),1)
    }else{
      f.push(kuerzel);
    }
    setAppStatus({
      ...appStatus,
      selectedFlags : f
    });
  }
  return(
    <button className={`rounded-2xl h-[60px] w-[60px] bg-disabledGrey ${appStatus.selectedFlags.includes(kuerzel) ? "ring-zanBlue ring-4" : ""}`}
            onClick={toggleFlag} title={beschreibung}>
      <img className={`object-fill scale-75 ${appStatus.selectedFlags.includes(kuerzel) ? "": ""}`} src={source} alt=""></img>
    </button>
  );
}

// Die Liste der Flags
function FlagList({album,appStatus,setAppStatus}){
  return(
    <div className="absolute left-[57vw] top-[16.5vh] w-[20vw] h-[9vh] bg-white">
      <div className={`h-full grid place-items-center ${(album.allowedPictureFlags.includes("vxpages_only") ? "grid-cols-5 w-[20vw]" : "grid-cols-4 w-[16vw]") }`}>
        <Flag appStatus={appStatus} setAppStatus={setAppStatus} beschreibung={'Visit-x im Bild'} kuerzel={"vx_branded"} source={'/icons/vx260.png'}/>
        <Flag appStatus={appStatus} setAppStatus={setAppStatus} beschreibung={'NS'} kuerzel={"nskv"} source={'/icons/nskv260.png'}/>
        <Flag appStatus={appStatus} setAppStatus={setAppStatus} beschreibung={'Fremde Person(en)'} kuerzel={"third_paty"} source={'/icons/3rd-party260.png'}/>
        <Flag appStatus={appStatus} setAppStatus={setAppStatus} beschreibung={'Fetisch'} kuerzel={"fetish"} source={'/icons/fetish260.png'}/>
        {(album.allowedPictureFlags.includes("vxpages_only") ? <Flag appStatus={appStatus} setAppStatus={setAppStatus} beschreibung={'Nur für VX-Pages'} kuerzel={"vxpages"} source={'/icons/vxpages260.png'}/> : <></>)}
      </div>
    </div>
  );
}

// Die Komponente für die Bildtags (die eigentlich nie verwendet werden)
function PictureTags({album,setAlbum,appStatus}){
  const [newTag,setNewTag] = useState("");

  // Adds one or more entered tags seperated by ,
  function addTag(){
    if(newTag.trim() === ''){
      return;
    }
    let curPic = -1;
    if(appStatus.viewMode === 0){
      curPic = appStatus.currentPic;
    }else if(appStatus.selectedPics.filter(i => i===true).length === 1){
      curPic = appStatus.selectedPics.findIndex(i => i===true);
    }else{
      return;
    }
    let t_old = album.photoalbum.pictures[curPic].allTags;
    let n_old = album.photoalbum.pictures[curPic].newTags;
    let t_add = newTag.split(',');
    for (let e in t_add){
      if(!t_old.includes(t_add[e].trim()) && !(t_add[e].trim() === "")){
        t_old.push(t_add[e].trim());
        n_old.push(t_add[e].trim());
      }
    }
    let pics = album.photoalbum.pictures;
    pics[curPic].allTags = t_old;
    pics[curPic].newTags = n_old;
    setAlbum({
      ...album,
      photoalbum : {
        ...album.photoalbum,
        pictures : pics
      }
    });
    setNewTag("");
  }

  // Deletes a single tag
  function removeTag(txt){
    let pics = album.photoalbum.pictures;
    if(appStatus.viewMode === 0){
      curPic = appStatus.currentPic;
    }else if(appStatus.selectedPics.filter(i => i===true).length === 1){
      curPic = appStatus.selectedPics.findIndex(i => i===true);
    }
    let t = pics[curPic].allTags;
    let n = pics[curPic].newTags;
    t.splice(pics[curPic].allTags.indexOf(txt),1);
    if(n.includes(txt)){
      n.splice(pics[curPic].newTags.indexOf(txt),1);
    }
    pics[curPic].allTags = t;
    pics[curPic].newTags = n;
    setAlbum({
      ...album,
      photoalbum : {
        ...album.photoalbum,
        pictures : pics
      }
    });
  }
  // Eventhandler for editing the to-be-added-tag
  function onChangeT(val){
    setNewTag(val);
  }
  // Generates the list of tags
  var tagList;
  let curPic = -1;
  if(appStatus.viewMode === 0){
    curPic = appStatus.currentPic;
  }else if(appStatus.selectedPics.filter(i => i===true).length === 1){
    curPic = appStatus.selectedPics.findIndex(i => i===true);
  }
  if(curPic !== -1){
    if (album.photoalbum.pictures[curPic].allTags.length > 0){
      tagList = album.photoalbum.pictures[curPic].allTags.map((t,index) => <Tag key={index} text={t} suspected={album.photoalbum.suspectedWords} newTags={album.photoalbum.pictures[curPic].newTags} oc={removeTag}/>);
    }else{
      tagList = "";
    }
  }
    // Adds the keydownHandler for Enter
  useEffect(()=>{
    let e = document.getElementById("photoTagInputField");
    function checkKey(e){
      if(e.repeat){
        return;
      }else if(e.key === "Enter"){
        addTag();
      }
    }
    e.addEventListener("keydown",checkKey);
    return () => {e.removeEventListener("keydown",checkKey)}
  });
  return(
    <div className="absolute left-[76vw] top-[27vh] w-[23vw] h-[14vh] bg-white dark:bg-slate-700 rounded-3xl">
      <div className="absolute top-0 left-0 h-10 w-full">
        <input id="photoTagInputField" className="rounded-full bg-disabledGrey text-black m-4 py-1 px-3 text-[15px]" type="text" size="30" value={newTag} placeholder="Insert picture tags" onChange={(e) => {onChangeT(e.target.value)}}></input>
        <button className={`${(appStatus.viewMode === 0 ||  appStatus.selectedPics.filter(i => i===true).length === 1 ? "text-zanBlue" : "text-disabledGrey")} my-2 w-7 h-7 inline-block align-middle text-[15px]`} onClick={addTag}>
          Apply
        </button>
      </div>
      <div className="absolute top-12 left-4 h-full w-[21vw]">
        <div className="h-[8vh] overflow-y-scroll">
          <ul className="">
            {tagList}
          </ul>
        </div>
      </div>
    </div>
  );
}

// Buttonleiste ganz unten auf der Webseite. Hier werden u.a. Alben geskippt, abgelehnt, gelöscht,...
function RejectAndReleaseButtons({album,appStatus,setAppStatus,saveAndSkipAlbum,skipAlbum,previousAlbum,hideAlbum}){
  function openRejectReasons(){
    setAppStatus({
      ...appStatus,
      rejectOverlay : true
    });
  }
  function openDelete(){
    setAppStatus({
      ...appStatus,
      deleteOverlay : true
    });
  }
  return(
    <div className="absolute left-[41.5vw] top-[80vh] w-[57.5vw] h-[7vh] bg-white dark:bg-slate-700 rounded-3xl grid-flow-col grid">
      <button className="px-4 py-1 my-3 mx-2 rounded-full font-bold text-zanBlue bg-white border-2 border-zanBlue" onClick={()=>saveAndSkipAlbum()}>
        {"Save Rating and Skip Album"}
      </button>
      <button className={`px-4 py-1 my-3 mx-2 rounded-full font-bold border-2 bg-white ${(sessionStorage.getItem("previous") !== null ? "text-zanBlue border-zanBlue":"text-disabledGrey border-disabledGrey")}`} onClick={()=>previousAlbum()}>
        {"< Previous Album"}
      </button>
      <button className="px-4 py-1 my-3 mx-2 rounded-full font-bold text-zanBlue bg-white border-2 border-zanBlue" onClick={skipAlbum}>
        {"Skip Album >"}
      </button>
      <button className={`px-4 py-1 my-3 mx-2 rounded-full font-bold border-2 bg-white ${(album.isButtonHideAllowed?"text-zanBlue border-zanBlue ":"text-disabledGrey border-disabledGrey")}`} onClick={(album.isButtonHideAllowed ? hideAlbum : null)}>
        Hide Album
      </button>
      <button className={`px-4 py-1 my-3 mx-2 rounded-full font-bold border-2 bg-white ${(album.isButtonRejectAllowed?"text-zanBlue border-zanBlue ":"text-disabledGrey border-disabledGrey")}`} onClick={(album.isButtonRejectAllowed ? openRejectReasons : null)}>
        Reject Album
      </button>
      <button className={`px-4 py-1 my-3 mx-2 rounded-full font-bold border-2 bg-white ${(album.isButtonDeleteAllowed?"text-zanRed border-zanRed":"text-disabledGrey border-disabledGrey")}`} onClick={(album.isButtonDeleteAllowed ? openDelete : null)}>
        Delete Album
      </button>
    </div>
  );
}

// Die Komponente mit der Liste der Rejectreasons
function RejectReasons({album,appStatus,setAppStatus}){
  let rr = album.allowedPictureRejectionReasons.map((r,index) => <RejectReasonButton ownRating={index} key={index} appStatus={appStatus} setAppStatus={setAppStatus} text={r.label} id={r.id}/>);
  return(
    <div className="absolute left-[41.5vw] top-[27vh] w-[33.5vw] h-[17vh] bg-white dark:bg-slate-700 rounded-3xl">
      <p className="text-black font-bold absolute top-3 left-4">Reject Reasons</p>
      <div className="absolute top-8 left-2">
        {rr}
      </div>
    </div>
  );
}

// Ein einzelner Ablehngrund
function RejectReasonButton({ownRating,appStatus,setAppStatus,text,id}){
  // Sets a rejectreason as rating
  function setRating(){
    setAppStatus({
      ...appStatus,
      selectedRating : ownRating,
      selectedRejection : id
    });
  }
  return(
    <button className={`rounded-full ${ownRating===appStatus.selectedRating ? "bg-zanRed text-white" : "text-black bg-disabledGrey"} px-2 py-1 mx-2 my-1`} onClick={setRating}>
      {text}
    </button>
  );
}

// Die Leiste an Elementen oben rechts. Dort kann nach Alben gesucht werden, aber auch nach alter und slots sortiert
function SearchAndFilter({album,appStatus,setAppStatus}){
    let slots = [<option key={-1} value="-1">ALL</option>].concat(album.allowedSlots.map((s) => <option key={s} value={s}>{s}</option>));
    const [searchAlbumValue,setSearchAlbumValue] = useState("");
    const [searchUserValue,setSearchUserValue] = useState("");

    function changeSort(e){
      setAppStatus({
        ...appStatus,
        sort : e.target.value
      });
    }
    function changeSlot(e){
      setAppStatus({
        ...appStatus,
        slot : e.target.value
      });
      sessionStorage.setItem("slot",e.target.value);
    }
    function updateAlbumSearch(e){
      setSearchAlbumValue(e.target.value);
    }
    function searchAlbum(){
      setAppStatus({
        ...appStatus,
        albumId : searchAlbumValue
      });
      /*if(searchValue === ""){
        window.location.pathname = "/photoRating";
      }else{
        window.location.pathname = "/photoRating/" + searchValue;
      }*/
    }

    function updateUserSearch(e){
      setSearchUserValue(e.target.value);
    }
    function searchUser(){
      setAppStatus({
        ...appStatus,
        userId : searchUserValue
      });
    }

  return(
    <div className="absolute -top-[5.5vh] right-[1vw]">
      <input className="bg-zanGrey rounded-full px-3 h-[30px] text-[15px]" type="number" placeholder="search for UserID" value={searchUserValue} onChange={(e)=>updateUserSearch(e)}></input>
      <button className="text-zanBlue mx-3 text-[15px]" onClick={searchUser}>Search</button>
      <input className="bg-zanGrey rounded-full px-3 h-[30px] text-[15px]" type="number" placeholder="search for AlbumID" value={searchAlbumValue} onChange={(e)=>updateAlbumSearch(e)}></input>
      <button className="text-zanBlue mx-3 text-[15px]" onClick={searchAlbum}>Search</button>
      <p className="inline ml-3 text-[15px]">Sort:</p>
      <select className="inline mx-3 px-3 bg-zanGrey rounded-full text-black text-[15px] h-[30px]" value={appStatus.sort} onChange={(e)=>changeSort(e)}>
        <option value={true}>Newest</option>
        <option value={false}>Oldest</option>
      </select>
      <p className="inline ml-3 text-[15px]">Slot:</p>
      <select className="inline mx-3 px-3 bg-zanGrey rounded-full text-black text-[15px] h-[30px]" value={appStatus.slot} onChange={(e)=>changeSlot(e)}>
        {slots}
      </select>
    </div>
  );
}