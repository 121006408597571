import React from "react";
import NavBar from "./NavBar";
import { getCookie, getJobs, loginToLustagenten, loginToTelegram } from "../scripts/xhrf";

export default function HomeApp(){

  const ratingCat = 99;   // Main Category number

  return(
    <div className="dark:text-white min-w-[1750px] font-zanFont">
      <NavBar ratingCat={ratingCat}/>
      <div className="absolute top-[10vh] w-full">
        <h1 className=" pl-[60px] pt-[40px] font-bold text-4xl h-24 text-transparent bg-clip-text bg-black">{"Content Rating Tool   "}<span className="text-base">Version 08.02.2024</span></h1>
      </div>
      <MenuGrid globalJobQueue={getJobs()}/>
    </div>
  );
}

function MenuGrid({globalJobQueue}){
  // Hier werden die Anzahl der Jobs pro Hauptkategorie zusammengefasst

  let pc = globalJobQueue.photoRatingCounts ? globalJobQueue.photoRatingCounts.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0) : -1;
  let prc = globalJobQueue.photoReratingCounts ? globalJobQueue.photoReratingCounts.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0) : -1;
  let vc = globalJobQueue.videoRatingCounts ? globalJobQueue.videoRatingCounts.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0) : -1;
  let vrc = globalJobQueue.videoReratingCounts ? globalJobQueue.videoReratingCounts.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0) : -1;
  let texts = globalJobQueue.textRatingCounts ? globalJobQueue.textRatingCounts.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0) : -1;
  let comments = globalJobQueue.commentRatingCounts ? globalJobQueue.commentRatingCounts.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0) : -1;
  let tc = texts < 0 && comments < 0 ? -1 : ((texts < 0 ? 0 : texts) + (comments < 0 ? 0 : comments));
  let lac = globalJobQueue.lustagentenRatingCounts ? globalJobQueue.lustagentenRatingCounts.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0) : -1;
  let mc = globalJobQueue.modelVerificationCounts ? globalJobQueue.modelVerificationCounts.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0) : -1;
  let tlc = globalJobQueue.telegramBroadcasts ? globalJobQueue.telegramBroadcasts.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0) : -1;
  let lf = globalJobQueue.loginFitness ? globalJobQueue.loginFitness.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0) : -1;
  let cn = globalJobQueue.communityNicknames ? globalJobQueue.communityNicknames.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0) : -1;
  let nh = globalJobQueue.service0900 ? globalJobQueue.service0900.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0) : -1;
  let fsk16Chats = globalJobQueue.fsk16Chats ? globalJobQueue.fsk16Chats.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0) : -1;
  let liveStreams = globalJobQueue.liveStreams ? globalJobQueue.liveStreams.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0) : -1;
  let otherAllowed = mc + fsk16Chats + liveStreams + lac + tlc + cn + lf + nh > -8;
  // isInt wird verwendet um bestimmte Button anzuzeigen oder auszublenden. Sollte der Cookie manuell gesetzt werden verhindert das Backend den unsachgemäßen gebrauch
  const isInt = getCookie("internal")==="X";
  //console.log(globalJobQueue);  // Debug info

  return(
    <div className={`absolute top-[24vh] left-[3vw] grid grid-cols-6 w-[90vw] h-[60vh]`}>
      {pc > -1 && <div className="bg-white rounded-3xl mr-8 pt-[50px] pb-[50px] px-[10px] w-[14vw]">
        <div className={`grid grid-rows-8 ${(isInt ? "h-[48vh]" : "h-[40vh]" )}`}>
          <CatTitle text={"PHOTO("+pc+")"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={1} secondaryCat={0} location={"/photoRating"} catName={"FEED"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={1} secondaryCat={1} location={"/photoRating"} catName={"SEDCARD"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={1} secondaryCat={2} location={"/photoRating"} catName={"SHOP"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={1} secondaryCat={3} location={"/photoRating"} catName={"FREE"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={1} secondaryCat={4} location={"/photoRating"} catName={"DATING"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={1} secondaryCat={5} location={"/photoRating"} catName={"POOL"}/>
          {isInt && <CatButton globalJobQueue={globalJobQueue} mainCat={1} secondaryCat={6} location={"/photoRating"} catName={"CAMPAIGN"}/>}
        </div>
      </div>}

      {prc > -1 && <div className="bg-white rounded-3xl mr-8 pt-[50px] pb-[50px] px-[10px] w-[14vw]">
        <div className="grid grid-rows-8 h-[32vh]">
          <CatTitle text={"PHOTO-RECHECK("+prc+")"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={2} secondaryCat={0} location={"/photoRating"} catName={"SEDCARD"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={2} secondaryCat={1} location={"/photoRating"} catName={"FREE"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={2} secondaryCat={2} location={"/photoRating"} catName={"DATING"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={2} secondaryCat={3} location={"/photoRating"} catName={"CAMPAIGN"}/>
        </div>
      </div>}

      {vc > -1 && <div className="bg-white rounded-3xl mr-8 pt-[50px] pb-[50px] px-[10px] w-[14vw]">
        <div className="grid grid-rows-8 h-[40vh]">
          <CatTitle text={"VIDEO("+vc+")"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={3} secondaryCat={0} location={"/videoRating"} catName={"SHOP"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={3} secondaryCat={1} location={"/videoRating"} catName={"FREE"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={3} secondaryCat={2} location={"/videoRating"} catName={"POOL"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={3} secondaryCat={3} location={"/videoRating"} catName={"CAMPAIGN"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={3} secondaryCat={4} location={"/videoRating"} catName={"FEED"}/>
        </div>
      </div>}

      {vrc > -1 && <div className="bg-white rounded-3xl mr-8 pt-[50px] pb-[50px] px-[10px] w-[14vw]">
        <div className="grid grid-rows-8 h-[32vh]">
          <CatTitle text={"VIDEO-RECHECK("+vrc+")"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={4} secondaryCat={0} location={"/videoRating"} catName={"SHOP"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={4} secondaryCat={1} location={"/videoRating"} catName={"FREE"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={4} secondaryCat={3} location={"/videoRating"} catName={"CAMPAIGN"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={4} secondaryCat={2} location={"/videoRating"} catName={"FEED"}/>
        </div>
      </div>}

      {tc > -1 && <div className="bg-white rounded-3xl mr-8 pt-[50px] pb-[50px] px-[10px] w-[14vw]">
        <div className="grid grid-rows-8 h-[24vh]">
          <CatTitle text={"TEXT("+tc+")"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={5} secondaryCat={0} location={"/commentRating"} catName={"FEED"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={5} secondaryCat={1} location={"/commentRating"} catName={"SHOP"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={6} secondaryCat={2} location={"/textRating"} catName={"VX"}/>
          <CatButton globalJobQueue={globalJobQueue} mainCat={14} secondaryCat={3} location={"/powerMailing"} catName={"POWER_MAILING"}/>
          {/*isInt &&<CatButton globalJobQueue={globalJobQueue} mainCat={15} secondaryCat={4} location={"/badwords"} catName={"POWER_MAILING"}/>*/}
        </div>
      </div>}

      {otherAllowed && <div className="bg-white rounded-3xl mr-8 pt-[50px] pb-[50px] px-[10px] w-[14vw]">
        <div className={`grid grid-rows-8 ${(isInt ? "h-[48vh]" : "h-[32vh]" )}`}>
          <CatTitle text={"OTHERS"}/>
          {mc > -1 && <div className={`row-span-1 ml-[1vw]`}>
            <button className={`bg-zanBlue hover:bg-blue-700 text-white rounded-full px-3 py-1 my-2 text-lg`} onClick={(e) => window.location.href = "/modelVerification"}>
              {"Model Verification (" + mc + ")"}
            </button>
          </div>}
          {fsk16Chats > -1 && <div className={`row-span-1 ml-[1vw]`}>
            <button className={`bg-zanBlue hover:bg-blue-700 text-white rounded-full px-3 py-1 my-2 text-lg`} onClick={(e) => window.location.href = "/fsk16Review"}>
              {"FSK 16 Chat Review"}
            </button>
          </div>}
          {liveStreams > -1 && <div className={`row-span-1 ml-[1vw]`}>
            <button className={`bg-zanBlue hover:bg-blue-700 text-white rounded-full px-3 py-1 my-2 text-lg`} onClick={(e) => window.location.href = "/streamMonitor"}>
              {"Livestreams Monitoring"}
            </button>
          </div>}
          {lac > -1 && <div className={`row-span-1 ml-[1vw]`}>
            <button className="text-white bg-zanBlue hover:bg-blue-700 rounded-full px-3 py-1 my-2 text-lg" onClick={()=>loginToLustagenten()}>
              {"Lustagenten(" + lac + ")"}
            </button>
          </div>}
          {tlc > -1 && <div className={`row-span-1 ml-[1vw]`}>
            <button className="text-white bg-zanBlue hover:bg-blue-700 rounded-full px-3 py-1 my-2 text-lg" onClick={()=>loginToTelegram()}>
              {"Telegram(" + tlc + ")"}
            </button>
          </div>}
          {cn > -1 && <div className={`row-span-1 ml-[1vw]`}>
            <button className={`bg-zanBlue hover:bg-blue-700 text-white rounded-full px-3 py-1 my-2 text-lg`} onClick={(e) => window.location.href = "/datingNicknames"}>
              {globalJobQueue.communityNicknames[0].label + "(" + cn + ")"}
            </button>
          </div>}
          {lf > -1 && <div className={`row-span-1 ml-[1vw]`}>
            <button className={`bg-zanBlue hover:bg-blue-700 text-white rounded-full px-3 py-1 my-2 text-lg`} onClick={(e) => window.location.href = "/loginFitness"}>
            {globalJobQueue.loginFitness[0].label + "(" + lf + ")"}
            </button>
          </div>}
          {nh > -1 && <div className={`row-span-1 ml-[1vw]`}>
            <button className={`bg-zanBlue hover:bg-blue-700 text-white rounded-full px-3 py-1 my-2 text-lg`} onClick={(e) => window.location.href = "/zeroNinehundred"}>
            {globalJobQueue.service0900[0].label + "(" + nh + ")"}
            </button>
          </div>}
        </div>
      </div>}
    </div>
  );
}

// Komponente für die Menübuttons
function CatButton({globalJobQueue,mainCat,secondaryCat,location,catName}){
  let c = 0;
  let disabled = true;
  let text = "";
  // Je nach Hauptkategorie werden die entsprechenden Daten gelesen
  if(mainCat === 1 && globalJobQueue.photoRatingCounts){
    c = globalJobQueue.photoRatingCounts.find(i=>i.type===catName).counts;
    disabled = c===0;
    text = globalJobQueue.photoRatingCounts.find(i=>i.type===catName).label;
  }else if(mainCat === 2 && globalJobQueue.photoReratingCounts){
    c = globalJobQueue.photoReratingCounts.find(i=>i.type===catName).counts;
    disabled = c===0;
    text = globalJobQueue.photoReratingCounts.find(i=>i.type===catName).label;
  }else if(mainCat === 3 && globalJobQueue.videoRatingCounts){
    c = globalJobQueue.videoRatingCounts.find(i=>i.type===catName).counts;
    disabled = c===0;
    text = globalJobQueue.videoRatingCounts.find(i=>i.type===catName).label;
  }else if(mainCat === 4 && globalJobQueue.videoReratingCounts){
    c = globalJobQueue.videoReratingCounts.find(i=>i.type===catName).counts;
    disabled = c===0;
    text = globalJobQueue.videoReratingCounts.find(i=>i.type===catName).label;  
  }else if(mainCat === 6 && globalJobQueue.textRatingCounts){
    c = globalJobQueue.textRatingCounts.map(i=>i.counts).reduce((acc,cur) => acc + cur, 0);
    disabled = c===0;
    text = globalJobQueue.textRatingCounts.find(i=>i.type===catName).label;
  }else if(mainCat === 5 && globalJobQueue.commentRatingCounts){
    c = globalJobQueue.commentRatingCounts.find(i=>i.type===catName).counts;
    disabled = c===0;
    text = globalJobQueue.commentRatingCounts.find(i=>i.type===catName).label;
  }else if(mainCat === 14 && globalJobQueue.powerMailing){
    c = globalJobQueue.powerMailing.find(i=>i.type===catName).counts;
    disabled = c===0;
    text = globalJobQueue.powerMailing.find(i=>i.type===catName).label;
  }else if(mainCat === 15){
    c = 0;
    disabled = c===0;
    text = "Badwords";
  }

  // Im sessionstorage wird die aktuelle Unterkategorie gespeichert.
  function handleOnClick(val){
    if(!disabled || [5,6,14,15].includes(mainCat)){
      sessionStorage.setItem('sc',val);
      if(mainCat===2 || mainCat===4){
        sessionStorage.setItem('mode','recheck');
      }else{
        sessionStorage.setItem('mode','check');
      }
      window.location.href = location;
    }
  }

  return(
    <div className={`row-start-${secondaryCat+2} row-span-1 ml-[1vw]`}>
        <button className={`${disabled ? "bg-disabledGrey text-disabledGreyText" : "bg-zanBlue hover:bg-blue-700 text-white"} rounded-full px-3 py-1 my-2 text-lg`} onClick={(e) => handleOnClick(catName)}>
          { text + " (" + c + ")"}
          </button>
    </div>
  );
}

// Komponente für den Kategorientitel
function CatTitle({text}){
  return(
    <div className={`row-start-1 row-span-1 ml-[1vw] pb-[40px]`}>
      <h2 className="font-bold text-2xl">{text}</h2>
    </div>
  );
}